import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authReducer';
import drawerReducer from './drawerReducer';
import convListReducer from './convListReducer';

// Action type for resetting the state
const RESET_STATE = 'RESET_STATE';

// Root reducer with reset functionality
const appReducer = combineReducers({
  auth: authReducer,
  drawer: drawerReducer,
  conversation: convListReducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STATE) {
    // Return undefined to reset state
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;