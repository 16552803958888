import React, { useEffect, useState } from "react";
import zybraLogo from "../../assets/images/images/zebra_logo.png";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../services/Api"
import { useToast, validatePhoneNumber } from "../../utils/helpers/CommFun";

function Login() {

  const [phoneNumber, setPhoneNumber] = useState("");

  const navigate = useNavigate();
  const toast = useToast();

 //handle input change
   const handleInputChange = (event) => {
    const { value } = event.target;
    if (/^\d{0,10}$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validatePhoneNumber(phoneNumber)) {
      toast.fire({
        icon: "warning",
        title: "Please enter a valid 10-digit phone number",
      });
    } else {
      try {

        const data = {
          phone: phoneNumber,
          is_web_login:1
        };

        const response = await api.post('/user_login', data);
        if (response.code === 200) {

          toast.fire({
            icon: "success",
            title: response.message,
          });
          sessionStorage.setItem("user_data", JSON.stringify(response.result));

          //navigate to otp
          navigate("/otp");
        } else {

          toast.fire({
            icon: "error",
            title: response.message,
            customClass: {
              container: 'custom-toast' // Apply the class to the container
            }
          });
        }
      } catch (error) {
        console.error("error", error);
      }
    }
  };

  useEffect(() => {
    document.title = "Login Page"

  }, []);

  return (
    <>
      <div className="main-wrapper">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-sm-6 col-md-7 col-lg-5">
              <div className="login-box">
                <div className="card border-0 ">
                  <div className="card-title text-center ">
                    <div className="logo-box">
                      <Link to="/">
                        <img src={zybraLogo} alt="logo" />
                      </Link>
                    </div>
                    <h3>Log in to your account</h3>
                    <p>Welcome back! Please enter your details.</p>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="" className="form-label">
                          Mobile No <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control  "
                          id=""
                          value={phoneNumber}
                          maxLength={10}
                          onChange={handleInputChange}
                          placeholder="Mobile No"
                        />
                      </div>
                      <button type="submit" className="btn  main-bg">
                        Login
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
