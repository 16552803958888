import React, { useState, useEffect, useSelecter, useContext, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import api from '../../services/Api'
import { setStarredDrawer } from "../../redux/actions/drawerActions"
import moment from 'moment';
import { utcToLocalConvertor, formatDateBasedOnRecency2 } from "../../utils/helpers/CommFun"
import SocketContext from "../../context/SocketContext";
import { Socket } from 'socket.io-client';
import empty_msg_img from '../../assets/images/no_message.png'
import AudioMessage from './layout/customaudio'
import play_btn from '../../assets/images/video-play-btn.png'
import { Modal } from "react-bootstrap";

function StarredMessages({drawer}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [error, setError] = useState('')
    const [profilepic, setProfilepic] = useState('')
    const [OldMessage, setOldMessage] = useState('')
    const userData = useSelector((state) => state.auth.user);
    const [MessageList, setMessageList] = useState([]);
    const [userId, setUserId] = useState('')
    //get the token 
    const usertoken = localStorage.getItem('token')
    const { conversationList } = useSelector(state => state.conversation);
    const socket = useContext(SocketContext);
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userData'))
        setUserId(user.id)
    }, [])


    const getMessageList = async () => {
        try {
            setError(null);
            setMessageList([]);
            const response = await api.get("/web_star_message_list", usertoken);
            if (response.code === 200) {
                setMessageList(response.result);
            } else {
                setError('Failed to fetch star message list');
            }
        } catch (error) {
            setError('An error occurred while fetching the dashboard list');
            console.error(error);
            navigate("/");
            localStorage.removeItem('userData');
            localStorage.removeItem('token');
        } finally {
        }
    }
    useEffect(() => {
        getMessageList();
    }, []);
    const onmessageMarked = (socketResponse) => {
        //setMessageList(prevMessageList => [...prevMessageList, socketResponse]);
        getMessageList()
    }

    //On Star Message UnMarkd
    const onmessageUnMarked = (socketResponse) => {
        //  setMessageList(prevMessageList => [...prevMessageList, socketResponse]);
        getMessageList()
    }
    useEffect(() => {
        if (socket) {
            socket.on("star_message_marked", onmessageMarked);
            socket.on("star_message_unmarked", onmessageUnMarked)
            return () => {
                socket.off("star_message_marked", onmessageMarked);
                socket.off("star_message_unmarked", onmessageUnMarked);
            };
        }
    }, [socket]);

    //unstar function
    const unstar = async () => {
        const starMessageData = {
            Authorization: "Bearer " + usertoken,
            star_messages: MessageList.map(({ conv_id, message_id }) => ({
                conv_id,
                message_id
            })),
            star_status: 0
        };
        await socket.emit('star_unstar_message', starMessageData);
        setMessageList([]);
    }
    //media preview
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [mediaType, setMediaType] = useState('');
    const [mediaSrc, setMediaSrc] = useState('');
    const [playingMessageId, setPlayingMessageId] = useState(null);
    const [progresses, setProgresses] = useState({});
    const [currentTimes, setCurrentTimes] = useState({});
    const audioRefs = useRef({});

    const handlePlayPause = (messageId) => {
        if (playingMessageId && audioRefs.current[playingMessageId]) {
            audioRefs.current[playingMessageId].pause();
        }

        if (messageId === playingMessageId) {
            setPlayingMessageId(null);
        } else {
            setPlayingMessageId(messageId);
            if (audioRefs.current[messageId]) {
                audioRefs.current[messageId].play();
            }
        }
    };

    const handleTimeUpdate = (messageId, currentTime, duration) => {
        setCurrentTimes((prev) => ({ ...prev, [messageId]: currentTime }));
        setProgresses((prev) => ({ ...prev, [messageId]: (currentTime / duration) * 100 }));
    };

    const handleSeek = (messageId, newTime) => {
        if (audioRefs.current[messageId]) {
            const audio = audioRefs.current[messageId];
            audio.currentTime = newTime;
            setCurrentTimes((prev) => ({ ...prev, [messageId]: newTime }));
        }
    };
    const handleMediaClick1 = (src, type) => {
        setMediaSrc(src);
        setMediaType(type);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setMediaSrc('');
        setMediaType('');
    };
    const stopAllAudio = () => {
        Object.keys(audioRefs.current).forEach((messageId) => {
            const audio = audioRefs.current[messageId];
            if (audio) {
                audio.pause(); 
                audio.currentTime = 0; 
            }
        });
        audioRefs.current = {};
        setPlayingMessageId(null);
    };
    useEffect(()=>{stopAllAudio()
    },[drawer])
    return (
        <section id="main-box">
            <div className="container-box">
                <div className="right-sidebar">
                    <div className="main-contant">
                        <div className="container-fluid">
                            <section className="contant-box" id="chatSection">
                                <div className="row">
                                    <div className="left-user-box">
                                        <div className="contact-profile big ">
                                            <div className="call-setting-tab ms-0 justify-content-between w-100">
                                                <div className="ms-0 btn" onClick={() => dispatch(setStarredDrawer(false))}>
                                                    <i><FontAwesomeIcon icon={faArrowLeft} /></i>
                                                </div>
                                                <h5>Starred Messages</h5>
                                                <div className="call-setting-tab starred-message-dropdown">
                                                    {MessageList && MessageList.length > 0 && (
                                                        <div className="btn profile-setting-btn dropdown-toggle" data-bs-toggle="dropdown">
                                                            <i><FontAwesomeIcon icon={faEllipsisVertical} /></i>
                                                        </div>
                                                    )}

                                                    {MessageList && MessageList.length > 0 && (
                                                        <ul className="dropdown-menu dropdown-menu-dark">
                                                            <li><a className="dropdown-item" onClick={unstar}>Unstar all</a></li>
                                                        </ul>
                                                    )}
                                                </div>

                                            </div>
                                        </div>

                                        <div className="chat-box chat-box-left">
                                            <div className="star-message-wrapper" id="scroll">
                                                <>
                                                    {MessageList.length > 0 ? (
                                                        MessageList.map((message) => (
                                                            message.sender_id === userId ? (
                                                                <div className="star-message-box" key={message.message_id}>
                                                                    <div className="contact-profile card-header">
                                                                        <div className="user-info">
                                                                            <span className="sender-name">{message.sender_name}</span>
                                                                            <div className="dot"></div>
                                                                            <span className="reciver-name">{message.receiver_name}</span>
                                                                        </div>
                                                                        <div className="time">
                                                                            <span className="msg_time">{formatDateBasedOnRecency2(message.created_at)}</span>
                                                                            <i className="fa-solid fa-chevron-right"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="message">
                                                                        <div className="msg_cotainer_send">
                                                                            {message.content_type == 1 ? (
                                                                                <span>{message.content}</span>
                                                                            ) : message.content_type == 2 ? (
                                                                                <img src={message.content} alt="content" />
                                                                            ) : message.content_type == 3 ? (
                                                                                <div className="voice-message-box">  <AudioMessage
                                                                                    key={message.message_id}
                                                                                    message={message}
                                                                                    isPlaying={playingMessageId === message.message_id}
                                                                                    progress={progresses[message.message_id] || 0}
                                                                                    currentTime={currentTimes[message.message_id] || 0}
                                                                                    onPlayPause={handlePlayPause}
                                                                                    onSeek={handleSeek}
                                                                                    onTimeUpdate={handleTimeUpdate}
                                                                                    ref={(el) => { audioRefs.current[message.message_id] = el && el.getAudio(); }}
                                                                                /></div>

                                                                            ) : message.content_type == 4 ? (
                                                                                <div className="video-container" >
                                                                                    <div className="lightboxContainer">
                                                                                        <>
                                                                                            <img src={play_btn} alt="" className='video-play-btn' onClick={() => handleMediaClick1(message.content, 'video')} />
                                                                                            <img src={message.thumbnail} alt="thumbnail" /></>
                                                                                    </div>

                                                                                </div>



                                                                            ) : null}
                                                                        </div>
                                                                        <span className="msg_time_send">
                                                                            {moment(utcToLocalConvertor(message.created_at)).format("hh:mm a")}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="star-message-box" key={message.message_id}>
                                                                    <div className="contact-profile card-header">
                                                                        <div className="user-info">
                                                                            <span className="sender-name">{message.sender_name}</span>
                                                                            <div className="dot"></div>
                                                                            <span className="reciver-name">{message.receiver_name}</span>
                                                                        </div>
                                                                        <div className="time">
                                                                            <span className="msg_time">{formatDateBasedOnRecency2(message.created_at)}</span>
                                                                            <i className="fa-solid fa-chevron-right"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="message">
                                                                        <div className="msg_cotainer_send">
                                                                            {message.content_type == 1 ? (
                                                                                <>{message.content}</>
                                                                            ) : message.content_type == 2 ? (
                                                                                <img src={message.content} alt="content" />
                                                                            ) : message.content_type == 3 ? (
                                                                                <div className="voice-message-box">
                                                                                    <AudioMessage
                                                                                        key={message.message_id}
                                                                                        message={message}
                                                                                        isPlaying={playingMessageId === message.message_id}
                                                                                        progress={progresses[message.message_id] || 0}
                                                                                        currentTime={currentTimes[message.message_id] || 0}
                                                                                        onPlayPause={handlePlayPause}
                                                                                        onSeek={handleSeek}
                                                                                        onTimeUpdate={handleTimeUpdate}
                                                                                        ref={(el) => { audioRefs.current[message.message_id] = el && el.getAudio(); }}
                                                                                    />
                                                                                </div>
                                                                            ) : message.content_type == 4 ? (
                                                                                <div className="video-container" >
                                                                                <div className="lightboxContainer">
                                                                                    <>
                                                                                        <img src={play_btn} alt="" className='video-play-btn' onClick={() => handleMediaClick1(message.content, 'video')} />
                                                                                        <img src={message.thumbnail} alt="thumbnail" /></>
                                                                                </div>

                                                                            </div>
                                                                            ) : null}
                                                                        </div>
                                                                        <span className="msg_time_send">
                                                                            {moment(utcToLocalConvertor(message.created_at)).format("hh:mm a")}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        ))
                                                    ) : (

                                                        <div className="empty-message-box">
                                                            <img src={empty_msg_img} alt="" />

                                                            <p className="empty-message">No Star Message</p>
                                                        </div>
                                                    )}
                                                </>
                                            </div>



                                        </div>


                                    </div>

                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                                dialogClassName="preview-modal  modal-dialog-centered modal-lg"
                                show={modalIsOpen}
                                onHide={closeModal}
                            >
                                <div onClick={closeModal} className="modal-close-btn">×</div>
                                {mediaType === 'image' && (
                                    <img src={mediaSrc} height="100%"
                                        width="100%" alt="Full size preview" />
                                )}
                                {mediaType === 'video' && (
                                    <video controls height={500} width={1500} >
                                        <source src={mediaSrc} type="video/mp4" />

                                    </video>
                                )}

                            </Modal>

        </section>
    )
}

export default StarredMessages;
