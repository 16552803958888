export const SET_NEWCHAT_OPEN = 'SET_NEWCHAT_OPEN';
export const SET_ADDGROUP_OPEN = 'SET_ADDGROUP_OPEN';
export const SET_NEWGROUP_OPEN = 'SET_NEWGROUP_OPEN';
export const SET_SETTINGS_OPEN = 'SET_SETTINGS_OPEN';
export const SET_CONTACTINFO_OPEN = 'SET_CONTACTINFO_OPEN';
export const SET_GROUPINFO_OPEN = 'SET_GROUPINFO_OPEN';
export const SET_PROFILE_OPEN= 'SET_PROFILE_OPEN';
export const SET_STARRED_OPEN='SET_STARRED_MESSAGE';
export const SET_NEWGROUPRIGHT_OPEN = 'SET_NEWGROUPRIGHT_OPEN';
export const SET_GROUPUPDATE_OPEN = 'SET_GROUPUPDATE_OPEN';

export const setNewChatDrawer = (currState) => ({
    type: SET_NEWCHAT_OPEN,
    payload: currState
});
export const setAddGroupDrawer = (currState,isAddMember) => ({
    type: SET_ADDGROUP_OPEN,
    payload: { currState, isAddMember }
});
export const setNewGroupDrawer = (currState) => ({
    type: SET_NEWGROUP_OPEN,
    payload: currState
});
export const setSettingDrawer = (currState) => ({
    type: SET_SETTINGS_OPEN,
    payload: currState
});
export const setContactInfoDrawer = (currState) => ({
    type: SET_CONTACTINFO_OPEN,
    payload: currState
});
export const setGroupInfoDrawer = (currState) => {
   
    return {
        type: SET_GROUPINFO_OPEN,
        payload: currState
    };
};
export const setProfileDrawer = (currState) => ({
    type: SET_PROFILE_OPEN,
    payload: currState
});
export const setStarredDrawer = (currState) => ({
    type: SET_STARRED_OPEN,
    payload: currState
});
export const setNewGroupRightDrawer = (currState) => ({
    type: SET_NEWGROUPRIGHT_OPEN,
    payload: currState
});
export const setGroupUpdateDrawer = (currState) => ({
    type: SET_GROUPUPDATE_OPEN ,
    payload: currState
});

