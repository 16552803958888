import React from 'react'
import litechat_screen from "../../assets/images/litechat-screen.png"
import Apple_store from "../../assets/images/apple-store-btn.png"
import Google_play from "../../assets/images/google-play-btn.png"
function chat_landing() {
    return (
       
                    <div className="col-md-7 col-lg-8 col-xxl-9 p-0">
                      <div className="chat-box-right">
                        <div className="dwn-litechat-wrapper">
                          <div className="dwn-litechat-inner">
                            <div className="img-box">
                              <img src={litechat_screen} alt="litechat" />
                            </div>
                            <div className="text-box">
                              <h3>Download Litechat for Android & iOS</h3>
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            </div>
                            <div className="button-box">
                              <ul>
                                <li>
                                  <a href="#"><img src={Apple_store} alt="" /></a>
                                </li>
                                <li>
                                  <a href="#"><img src={Google_play} alt="" /></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="encryped-text">
                            <span><i className="fa-solid fa-lock me-2"></i>Your personal messages are end-to-end encrypted</span>
                          </div>
                        </div>
                      </div>
                    
        </div>)
}

export default chat_landing