import { SET_CONTACT_LIST, SET_CONV_LIST, SET_CURRCHAT_MEMBERS, SET_CURR_CHAT, SET_NEWGROUP_LIST } from "../actions/convListActions";
import { RESET_STATE } from "../actions/authActions";

const initialState = {
    conversationList: [],
    contactList: [],
    newGroupList: [],
    groupMembers: [],
    currChat: {}
};

const convListReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURR_CHAT:
            return {
                ...state,
                currChat: action.payload
            };
        case SET_CURRCHAT_MEMBERS:
            return {
                ...state,
                groupMembers: action.payload
            };
        case SET_CONTACT_LIST:
            return {
                ...state,
                contactList: action.payload
            };
        case SET_CONV_LIST:
            return {
                ...state,
                conversationList: action.payload
            };
        case SET_NEWGROUP_LIST:
            return {
                ...state,
                newGroupList: action.payload
            };
      
        case RESET_STATE:
            return initialState; // Reset to initial state
        default:
            return state;
    }
};

export default convListReducer;
