import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../src/assets/css/style.css";
import "../src/assets/css/responsive.css";
import App from "./App";
import { Provider } from 'react-redux';
import store from './redux/store';
import SocketContext from "./context/SocketContext";
// import { io } from "socket.io-client";
// import socket from "./services/socket";
import { OnlineStatusProvider } from "./context/OnlineStatusContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <OnlineStatusProvider>
    {/* <SocketContext.Provider value={socket}> */}
      <Provider store={store}>
        <App />
      </Provider>
    {/* </SocketContext.Provider> */}
  </OnlineStatusProvider>
);
