import React, { useState, useEffect, useRef } from 'react';
import camera from "../../assets/images/camera.png";
import profileImage from '../../assets/images/group-img.png';
import ok from "../../assets/images/ok.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { setGroupUpdateDrawer } from "../../redux/actions/drawerActions";
import { useToast } from "../../utils/helpers/CommFun";
import api from "../../services/Api";
import emoji from '../../assets/images/emoji-btn.png';
import Picker from 'emoji-picker-react';
import user from '../../assets/images/user.png';
import {  setCurrChat } from '../../redux/actions/convListActions';
function GroupUpdate() {
    const toast = useToast();
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [profilePic, setProfilePic] = useState(user);
    const [isEditingName, setIsEditingName] = useState(false);
    const [showImageIcon, setShowImageIcon] = useState(true);
    const [file, setFile] = useState(null);
    const userToken = localStorage.getItem('token');
    const emojiRef = useRef(null);
    const pickerRef = useRef(null);
    const [pickerToggler, setPickerToggler] = useState(false);
    const { currChat } = useSelector(state => state.conversation);
    const [currChatData, setcurrChatData] = useState(currChat);
    useEffect(() => {
        setName(currChat?.group_name || '');
        setProfilePic(currChat?.group_icon || user);
    }, [currChat]);

    useEffect(() => {
        return () => {
            if (profilePic instanceof File) {
                URL.revokeObjectURL(getProfilePicSrc());
            }
        };
    }, [profilePic]);

    const toggleEditName = () => {
        setIsEditingName(!isEditingName);
        setShowImageIcon(!isEditingName);
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setProfilePic(selectedFile);
        } else {
            setProfilePic(user); 
        }
    };

    const handleClickOutside = (event) => {
        if (
            pickerRef.current &&
            !pickerRef.current.contains(event.target) &&
            !emojiRef.current.contains(event.target)
        ) {
            setPickerToggler(false);
        }
        setShowImageIcon(true);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const onEmojiClick = (event, emojiObject) => {
        setName(prev => (prev + event.emoji));
        setShowImageIcon(false);
    };

    const name_change = (e) => {
        setName(e.target.value);
    };

    const submit = async () => {
        if (name === '') {
            toast.fire({
                icon: "error",
                title: "Please enter a name",
            });
            return false;
        }
        if (name === currChat?.group_name) {
            toast.fire({
                icon: "error",
                title: "Group Name must not same as previous",
            });
            return false;
        }

        try {
            const formData = new FormData();
            formData.append('group_name', name);
            if (profilePic instanceof File) {
                formData.append('group_icon', profilePic);
            }
            formData.append('conv_id', currChat.conv_id);
            formData.append('conv_type', currChat.conv_type);

            const response = await api.postFormData(`/update_group_info`, formData, userToken);
            if (response.code === 200) {
                toast.fire({
                    icon: "success",
                    title: "Group updated successfully",
                });
                let CurrChat = {
                    ...currChatData,
                    group_icon:response.result.response.group_icon ,
                    group_name:response.result.response.group_name,
                }
                dispatch(setCurrChat(CurrChat))
                dispatch(setGroupUpdateDrawer(false));
            } else {
                toast.fire({
                    icon: "error",
                    title: "Group can't update",
                });
            }
        } catch (error) {
            console.error("Error updating group:", error);
            toast.fire({
                icon: "error",
                title: "An error occurred",
            });
        }
    };

    const getProfilePicSrc = () => {
        if (profilePic === null) {
            return user;
        } else if (profilePic instanceof File) {
            return URL.createObjectURL(profilePic);
        } else if (typeof profilePic === 'string' && (profilePic.startsWith('http') || profilePic.startsWith('https'))) {
            return profilePic;
        } else {
            return user;
        }
    };

    const imgStyle = {
        width: '150px',
        height: '150px',
        borderRadius: '50%',
        objectFit: 'cover',
    };

    return (
        <div className="left-user-box">
            <div className="contact-profile big">
                <div className="call-setting-tab ms-0">
                    <div className="ms-0 btn" onClick={() => dispatch(setGroupUpdateDrawer(false))}>
                        <i><FontAwesomeIcon icon={faArrowLeft} /></i>
                    </div>
                    <h5>Group Update</h5>
                </div>
            </div>
            <div className="chat-box chat-box-left profile-setting">
                <div className="profile-info-box se">
                    <div className="row">
                        <div className="new-group-box d-block">
                            <div className="col-12">
                                <div className="input-group flex-column justify-content-center">
                                    <div className="image add-image">
                                        <div className="change-img add-group-icon">
                                            <img src={camera} alt="Camera" className="camera" />
                                            <span>Change <br />Profile Photo</span>
                                        </div>
                                        <img
                                            src={getProfilePicSrc()}
                                            alt="Profile"
                                            className="profile-update"
                                            style={imgStyle}
                                        />
                                        <input
                                            type="file"
                                            className="form-control"
                                            accept="image/gif, image/jpeg, image/png"
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group">
                                    <label className="form-label">Your Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={name}
                                        onChange={name_change}
                                    />
                                    <div className="emoji-box">
                                        <i><FontAwesomeIcon icon={faPen} /></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="next-btn-box">
                    <div className="img-box">
                        <img src={ok} alt="Submit" onClick={submit} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GroupUpdate;
