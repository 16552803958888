import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";

// Create a Context for the socket
const SocketContext = React.createContext();

const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const socketUrl = process.env.REACT_APP_SOCKET_URL;
    const userData = JSON.parse(localStorage.getItem('userData')) || null;

    // Initialize the socket connection
    const newSocket = io(socketUrl, {
      transports: ["websocket"],
      query: { userId: userData?.id },
    });

    // Handle socket events
    newSocket.on('connect', () => {
      console.log('Socket.IO connection established');
    });

    newSocket.on('connect_error', (error) => {
      console.error('Connection error:', error);
    });

    newSocket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
    });

    setSocket(newSocket);

    // Cleanup on unmount
    return () => {
      newSocket.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};


// Correctly exporting SocketContext as the default export
export default SocketContext;

// Exporting SocketProvider as a named export
export { SocketProvider };
