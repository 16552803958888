import React, { useContext, useEffect, useRef, useState, useMemo } from 'react'
import Chatwindow from './Chatwindow'
import ChatLanding from "./chat_landing"
import profile_image from '../../assets/images/profile.png'
import new_chat from '../../assets/images/new-chat.png'
import user from '../../assets/images/user.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faMagnifyingGlass, faPhotoFilm, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux'
import { performLogout } from '../../redux/actions/authActions'
import NewChat from './NewChat';
import AddGroupMember from './AddGroupMember'
import NewGroup from './NewGroup'
import '../../assets/css/drawer.css';
import { setAddGroupDrawer, setNewChatDrawer, setNewGroupDrawer, setSettingDrawer } from '../../redux/actions/drawerActions'
import { getContactList, getUserList, setConvList, setConversationMessage, setCurrChat } from '../../redux/actions/convListActions'
import Setting from '../Setting/Setting'
import SocketContext from '../../context/SocketContext'
import { formatDateBasedOnRecency, sortByCreatedAt, utcToLocalConvertor,processString } from '../../utils/helpers/CommFun'
import { setProfileDrawer, setStarredDrawer, setNewGroupRightDrawer } from '../../redux/actions/drawerActions';
import Profile from "../Profile"
import StarredMessages from "../Chat/StarredMessages"
import logo from '../../assets/images/images/zebra_logo.png'
import empty_msg_img from '../../assets/images/no_message.png'
import { debounce } from 'lodash';

function Chatlist() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const socket = useContext(SocketContext);

    const profileDrawerRef = useRef(null);
    const starredDrawerRef = useRef(null);
    const usertoken = localStorage.getItem('token')

    const { isNewChatOpen, isAddGroupOpen, isNewGroupOpen, isSettingsOpen, isProfileOpen, isStarredOpen } = useSelector(state => state.drawer);
    const { currChat, conversationList } = useSelector(state => state.conversation);
    const [conversationListUpdated, setConversationListUpdated] = useState([]);

    const [isChatWindowOpen, setChatWindowOpen] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [profilePic, setProfilePic] = useState('');
    const [error, seterror] = useState(null);
    const [chatList, setChatList] = useState([])
    const [listState, setListState] = useState(0); //0-ALL, 1- UNREAD, 2-GROUP
    const [search, setSearch] = useState('')
    let current_conv_id = useRef(null);
    const [activeConversation, setActiveConversation] = useState(null);

    const userData = JSON.parse(localStorage.getItem("userData"));

    const newChatDrawerRef = useRef(null);
    const addGroupDrawerRef = useRef(null);
    const newGroupDrawerRef = useRef(null);
    const settingsDrawerRef = useRef(null);


    useEffect(() => {
        setConversationListUpdated(conversationList);
    },
        [conversationList])

    //Set Profile picture
    useEffect(() => {
        const userDataString = localStorage.getItem('userData');
        if (userDataString) {
            const user = JSON.parse(userDataString);
            setProfilePic(user.profile_pic);
        } else {
        }
    });

    // Get Conversation list
    useEffect(() => {
        const fetchData = async () => {
            if (chatList.length <= 0) {
                setisLoading(true);
                let data = await dispatch(getUserList(navigate));
                if (data == 0) {
                    setisLoading(false);
                }
                dispatch(getContactList(conversationList, navigate));
            }
        };

        fetchData();
    }, [chatList.length, navigate, conversationList, dispatch]);

    // Get Users for new chat list 
    useEffect(() => {
        // Filtering out conversations where conv_type is "1" and messageList is empty

        // const filteredConversationList = conversationList.filter(conv => !(conv.conv_type === "1" && conv.messageList.length === 0));

        setChatList(conversationList);
        if (conversationList.length > 0) {
            setisLoading(false);
        }
    }, [conversationList])

    // Socket events 
    useEffect(() => {
        if (socket) {
            socket.on("create_group", onCreateGroup);
            socket.on("create_conversation", onCreateconv);
            socket.on("message_received", onReceivedMessage);
            return () => {
                socket.off("create_group", onCreateGroup);
                socket.off("create_conversation", onCreateconv);
                socket.off("message_received", onReceivedMessage);
            };
        }
    }, [socket]);


    //on event functions
    // on create group
    const onCreateGroup = (data) => {
        const adminMember = data.members.find(member => member.admin === "1");
        setChatList((conv) => {
            return [data.conversation, ...conv];
        });

        console.log("(IS ADMIN)", adminMember.user_id, userData.id);
        if (adminMember.user_id == userData.id) {
            setConversation(data.conversation, 1)
        }
    };

    //on create conversation one to one 
    const onCreateconv = (data) => {
        console.log("on create conv", data);
        console.log("uuu", data.user_id, userData.id);

        if (data.user_id != userData.id) {
            setChatList((prevMessages) => {
                return [data, ...prevMessages];
            });
            setConversation(data, 1)
        }
    };

    const update_message_status = async (data) => {
        if (data.sender_id !== userData.id) {
            const updateMessageStatus = {
                Authorization: "Bearer " + usertoken,
                message_ids: data.message_id,
                status: "2"
            };
            socket.emit("update_message_status", updateMessageStatus);
        }
    };

    const onReceivedMessage = (data) => {
        setChatList(prevChatList => {
            const receiverChat = prevChatList.find(conv => conv.conv_id === data.conv_id);
            if (receiverChat) {

                console.log("receive socket", data);

                // let updatedMessageList = receiverChat.messageList ? [...receiverChat.messageList, data] : [data];     

                if (current_conv_id && current_conv_id.current != data.conv_id) {
                    update_message_status(data);
                }

                let updatedMessageList = receiverChat.messageList ? [data, ...receiverChat.messageList] : [data];
                console.log("updatedMessageList---",updatedMessageList)

                if (!updatedMessageList[1].hasOwnProperty('updated_at'))
                    updatedMessageList.splice(1, 1);

                // Create an updated version of the conversation
                const updatedCurrChat = {
                    ...receiverChat,
                    messageList: updatedMessageList,
                    content: data.content,
                    content_type: data.content_type,
                    message_id: data.message_id,
                    client_id: data.client_id,
                    created_at: data.created_at,
                    updated_at: data.updated_at,
                    unread_count: (current_conv_id.current != data.conv_id && userData.id != data.sender_id) ? receiverChat.unread_count + 1 : 0,
                };



                let updatedConversationList = prevChatList.filter(conv => conv.conv_id !== data.conv_id);
                updatedConversationList.unshift(updatedCurrChat);


                // Dispatch the updated conversation list
                dispatch(setConvList([...updatedConversationList]));

                // Check if the updated conversation is the current chat
                if (current_conv_id.current == data.conv_id) {
                    dispatch(setCurrChat(updatedCurrChat));
                }

                return updatedConversationList;

            } else {
                const newConversation = {
                    conv_id: data.conv_id,
                    messageList: [data],
                    content: data.content,
                    content_type: data.content_type,
                    message_id: data.message_id,
                    client_id: data.client_id,
                    created_at: data.created_at,
                    updated_at: data.updated_at,
                    unread_count: userData.id != data.sender_id ? 1 : 0
                };
                let updatedConversationList = prevChatList.map(conv =>
                    conv.conv_id === data.conv_id ? newConversation : conv
                );
                // updatedConversationList = updatedConversationList.sort((a, b) => sortByCreatedAt(b, a));
                dispatch(setConvList([...updatedConversationList]));
                if (Object.keys(currChat).length === 0 || currChat.conv_id === data.conv_id) {
                    dispatch(setCurrChat(newConversation));
                }
                return updatedConversationList;
            }
        });
    };

    //Open chat window
    const setConversation = (data, flag) => {
        // Check if the current conversation is already active
        // if (current_conv_id.current === data.conv_id) {
        //     return;
        // }

        //open chat window
        if (!flag) {
            handleCancel();
        }
        setChatWindowOpen(true);
        setActiveConversation(data.conv_id);
        current_conv_id.current = data.conv_id;
        setChatList(prevChatList => {
            const receiverChat = prevChatList.find(conv => conv.conv_id === data.conv_id);
            if (receiverChat) {

                const updatedCurrChat = {
                    ...receiverChat,
                    unread_count: 0
                };

                let updatedConversationList = prevChatList.map(conv =>
                    conv.conv_id === data.conv_id ? updatedCurrChat : conv
                );

                dispatch(setConvList(updatedConversationList));
                dispatch(setCurrChat(updatedCurrChat));

                return updatedConversationList;
            }
            return prevChatList;
        });
    }

    const debouncedSetConversation = debounce(setConversation, 200); // 300ms delay

    //logout
    const logout = async () => {
        Swal.fire({
            title: "Logout",
            text: "Are you sure you want to Logout?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Logout",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(performLogout(navigate));
            }
        });
    };

    //Drawer open close management
    const handleClickOutside = (event) => {
        if (newChatDrawerRef.current &&
            !newChatDrawerRef.current.contains(event.target)
            //   && !newGroupDrawerRef.current.contains(event.target)
            && !addGroupDrawerRef.current.contains(event.target)
            && !settingsDrawerRef.current.contains(event.target)
            && !profileDrawerRef.current.contains(event.target)
            && !starredDrawerRef.current.contains(event.target)
        ) {
            dispatch(setNewChatDrawer(false));
            //  dispatch(setNewGroupDrawer(false));
            dispatch(setAddGroupDrawer(false, false));
            dispatch(setSettingDrawer(false));
            dispatch(setProfileDrawer(false));
            dispatch(setStarredDrawer(false));
        }
    };
    useEffect(() => {
        // Add or remove event listener based on any drawer being open
        if (isNewChatOpen || isNewGroupOpen || isAddGroupOpen || isSettingsOpen || isProfileOpen || isStarredOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isNewChatOpen, isNewGroupOpen, isAddGroupOpen, isSettingsOpen, isProfileOpen, isStarredOpen]);

    // Function to filter chatList based on listState
    const getFilteredChats = () => {
        switch (listState) {
            case 1: // Unread
                return chatList.filter(chat => chat.unread_count > 0);
            case 2: // Group
                return chatList.filter(chat => chat.conv_type == 2);
            default: // All
                return chatList;
        }
    };

    // Get the filtered chat list
    const filteredChatList = getFilteredChats();
    const filterList = (list, query) => {
        const lowerCaseQuery = query.toLowerCase().trim();
        if (!lowerCaseQuery) {
            return list;
        }
        const matchesQuery = (item) => {
            const username = item.username ? item.username.toLowerCase() : '';
            const groupName = item.group_name ? item.group_name.toLowerCase() : '';
            const number = item.number ? item.number.toString() : '';
            if (item.conv_type == 1) {
                return [username, number].some(field => field.includes(lowerCaseQuery));
            }
            if (item.conv_type == 2) {
                return groupName.includes(lowerCaseQuery);
            }
            return false;
        };
        return list.filter(matchesQuery);
    };


    //search function
    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearch(query);
        const filteredUsers = filterList(conversationListUpdated, query);
        setChatList(filteredUsers);
    };
    //handle cancel
    const handleCancel = () => {
        setSearch('');
        const filteredUsers = filterList(conversationListUpdated, "");
        setChatList(filteredUsers);
    };
    return (
        <>
            {/* Chatlist */}
            <div className="col-md-5 col-lg-4 col-xxl-3 p-0  drawer-container">
                <div className="left-user-box">
                    <div className="contact-profile card-header">
                        <img src={profilePic === null ? profile_image : profilePic} alt="profile" onClick={() => dispatch(setProfileDrawer(true))} />
                        <div className="call-setting-tab">
                            <div className="btn" onClick={() => dispatch(setNewChatDrawer(true))}>
                                <img src={new_chat} alt="" />
                            </div>
                            <div
                                className="btn profile-setting-btn dropdown-toggle"
                                data-bs-toggle="dropdown">
                                <i><FontAwesomeIcon icon={faEllipsisVertical} /></i>
                            </div>
                            <ul className="dropdown-menu dropdown-menu-dark">
                                <li>
                                    <div className="dropdown-item" onClick={() => dispatch(setAddGroupDrawer(true, false)) /* open drawer for new group */}>
                                        New group
                                    </div>
                                </li>
                                <li>
                                    <div className="dropdown-item" onClick={() => dispatch(setStarredDrawer(true))}>
                                        Starred Messages
                                    </div>
                                </li>
                                <li>
                                    <div className="dropdown-item" onClick={() => dispatch(setSettingDrawer(true))} >
                                        Settings
                                    </div>
                                </li>
                                <li>
                                    <Link className="dropdown-item" onClick={logout}>
                                        Log out
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="chat-box chat-box-left">
                        <div className="serach-button-tab">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group" style={{ position: 'relative' }}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            id=""
                                            placeholder="Search"
                                            value={search}
                                            onChange={handleSearchChange}

                                        />
                                        <div style={{ position: 'absolute', top: '50%', left: '15px', transform: 'translateY(-50%)', color: 'var(--white)', fontSize: '18px' }}>
                                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                                        </div>
                                        {
                                            search && (<div style={{ position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)', color: 'var(--white)', fontSize: '18px' }}>
                                                <FontAwesomeIcon icon={faXmark} onClick={handleCancel} />
                                            </div>)
                                        }

                                    </div>


                                </div>
                            </div>
                        </div>
                        <nav className="">
                            <div
                                className="nav nav-tabs border-0 "
                                id="nav-tab"
                                role="tablist"
                            >
                                <button
                                    className="nav-link active d-flex justify-content-center align-items-center"
                                    id="nav-User-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-User"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-User"
                                    aria-selected="true"
                                    onClick={() => setListState(0)}
                                >
                                    All
                                </button>

                                <button
                                    className="nav-link d-flex justify-content-center align-items-center"
                                    id="nav-User-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-User"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-User"
                                    aria-selected="true"
                                    onClick={() => setListState(1)}
                                >
                                    Unread
                                </button>
                                <button
                                    className="nav-link d-flex justify-content-center align-items-center"
                                    id="nav-User-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-User"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-User"
                                    aria-selected="true"
                                    onClick={() => setListState(2)}
                                >
                                    Group
                                </button>

                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            {/* All Messages*/}
                            <div
                                className="tab-pane fade active show"
                                id="nav-User"
                                role="tabpanel"
                                aria-labelledby="nav-User-tab"
                            >
                                <nav className="user-chat-nav" id="scrollbar">
                                    {
                                        filteredChatList && filteredChatList.length > 0 ? (
                                            filteredChatList.map((data) => (
                                                <div
                                                    onClick={() => debouncedSetConversation(data)}
                                                    className="nav nav-tabs border-0"
                                                    id="nav-tab"
                                                    role="tablist"
                                                    key={data.conv_id}
                                                >
                                                    <button
                                                        className={`nav-link chat-btn ${activeConversation === data.conv_id ? 'active' : ''}`}
                                                        type="button"
                                                        role="tab"
                                                    >
                                                        <div className="user-chat-box">
                                                            <Link
                                                                className="d-flex align-items-center active"
                                                                id="chat-link"
                                                            >
                                                                <div className="img-box">
                                                                    <img
                                                                        src={
                                                                            data.conv_type === "2"
                                                                                ? (data.group_icon ? data.group_icon : user)
                                                                                : (data.profile_pic ? data.profile_pic : user)
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="user-info text-start">
                                                                    <h5>
                                                                        {data.conv_type === "2" ? data.group_name : data.username}
                                                                    </h5>
                                                                    <p>
                                                                        
                                                                        {data.messageList[0]?.content_type == "1" ? (
                                                                         
                                                                          processString(data.messageList[0]?.content)
                                                                        ) : data.messageList[0]?.content_type== "2" ? (
                                                                            <span><FontAwesomeIcon icon={faPhotoFilm} /> Image</span>
                                                                        ) : data.messageList[0]?.content_type == "3" ? (
                                                                            <span><FontAwesomeIcon icon={faPhotoFilm} /> Audio</span>
                                                                        ) :data.messageList[0]?.content_type== "4" ? (
                                                                            <span><FontAwesomeIcon icon={faPhotoFilm} /> Video</span>
                                                                        ) : null}
                                                                    </p>
                                                                    
                                                                </div>
                                                                <div className="chat-time ms-auto text-center">
                                                                    <p className="time">{formatDateBasedOnRecency(data.created_at)}</p>
                                                                    {activeConversation !== data.conv_id && (
                                                                        <div>
                                                                            {data.unread_count > 0 && (
                                                                                <span>{data.unread_count}</span>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </button>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="empty-message-box">
                                                <img src={empty_msg_img} alt="" />
                                                <p className="empty-message">No Chat list found</p>
                                            </div>
                                        )
                                    }


                                </nav>
                            </div>

                        </div>
                    </div>
                </div>
                {/* New Chat */}
                <div ref={newChatDrawerRef} className={`drawer ${isNewChatOpen ? 'open' : ''}`}>
                    <NewChat setChatList={setChatList} setChatWindowOpen={setChatWindowOpen} setActiveConversation={setActiveConversation} drawer={isNewChatOpen} />
                </div>
                {/* Add New group member */}
                <div ref={addGroupDrawerRef} className={`drawer ${isAddGroupOpen ? 'open' : ''}`}>
                    <AddGroupMember drawer={isAddGroupOpen} />
                </div>
                {/* New group */}
                <div ref={newGroupDrawerRef} className={`drawer ${isNewGroupOpen ? 'open' : ''}`}>
                    <NewGroup />
                </div>

                <div ref={settingsDrawerRef} className={`drawer ${isSettingsOpen ? 'open' : ''}`}>
                    <Setting />
                </div>

                <div ref={profileDrawerRef} className={`drawer ${isProfileOpen ? 'open' : ''}`}>
                    <Profile profile={isProfileOpen}  />
                </div>

                <div ref={starredDrawerRef} className={`drawer ${isStarredOpen ? 'open' : ''}`}>
                    <StarredMessages  drawer={isStarredOpen}/>
                </div>
            </div>



            {isChatWindowOpen ? <Chatwindow socket={socket} /> : <ChatLanding />}

            {isLoading &&
                <div className="loader-box">
                    <div className="img-box">
                        <img src={logo} alt="" />
                    </div>
                    <div className="loaders data"></div></div>
            }
        </>
    )
}

export default Chatlist
