import React, { useContext, useEffect, useState, createRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEllipsisVertical, faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import new_user_add from '../../assets/images/new-user-add.png'
import user from '../../assets/images/user.png'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector, } from 'react-redux';
import { setAddGroupDrawer, setNewChatDrawer } from '../../redux/actions/drawerActions';
import { sortAlphabetic } from '../../utils/helpers/CommFun';
import SocketContext from '../../context/SocketContext';
import { sortByCreatedAt } from '../../utils/helpers/CommFun'
import { setConvList, setCurrChat } from '../../redux/actions/convListActions'
import empty_msg_img from '../../assets/images/no_message.png'
import { useToast } from "../../utils/helpers/CommFun"


function NewChat({ setChatList, setChatWindowOpen, setActiveConversation, drawer }) {
    const dispatch = useDispatch();
    const { contactList, conversationList } = useSelector(state => state.conversation);
    const [UserList, setUserList] = useState([])
    const [OldUserList, setOldUserList] = useState([])

    const userToken = localStorage.getItem('token')
    const userData = JSON.parse(localStorage.getItem('userData'))
    const socket = useContext(SocketContext);

    const [search, setSearch] = useState('')


    const getContactList = async () => {
        const contactConvList = [...contactList].sort(sortAlphabetic);
        const chatsFilter = contactConvList.filter(item => item.conv_type ==null);
        setUserList(chatsFilter);
        setOldUserList(chatsFilter)
    }


    //Open chat window
    const setConversation = (data) => {

        //open chat window
        setChatWindowOpen(true);
        setActiveConversation(data.conv_id);
        if (data.conv_id !== null) {
            setChatList(prevChatList => {
                const receiverChat = prevChatList.find(conv => conv.conv_id === data.conv_id);

                console.log("receiverChat---", receiverChat)

                if (receiverChat) {
                    const updatedCurrChat = {
                        ...receiverChat,
                        unread_count: 0
                    };

                    let updatedConversationList = prevChatList.map(conv =>
                        conv.conv_id === data.conv_id ? updatedCurrChat : conv
                    );
                    updatedConversationList = updatedConversationList.sort((a, b) => sortByCreatedAt(b, a))

                    dispatch(setConvList(updatedConversationList));
                    dispatch(setCurrChat(updatedCurrChat));
                    dispatch(setNewChatDrawer(false));

                    return updatedConversationList;
                }
                return prevChatList;
            });
        }else{
            dispatch(setCurrChat(data));
        }


    }

    const createNewConv = async (data) => {
        setConversation(data);

    };

    useEffect(() => {
        getContactList();
    }, [contactList, conversationList]);

    //serching functionality
    const filterList = (list, query) => {
        const lowerCaseQuery = query.toLowerCase().trim();
        if (!lowerCaseQuery || lowerCaseQuery === null) {
            return OldUserList
        }
        return list.filter(item => {
            const contactName = item.contact_name ? item.contact_name.toLowerCase() : '';
            const number = item.number ? item.number.toString() : '';
            const match = contactName.includes(lowerCaseQuery) || number.includes(lowerCaseQuery);
            return match;
        });
    };

    //search function
    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearch(query);
        const filteredUsers = filterList(UserList, query);
        setUserList(filteredUsers);
    };
    const messagesEndRef = createRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView();
    };
    useEffect(() => { scrollToBottom() }, [drawer])
    return (
        <div className="left-user-box">
            <div className="contact-profile big">
                <div className="call-setting-tab ms-0">
                    <div className="ms-0 btn" onClick={() => dispatch(setNewChatDrawer(false))} >
                        <i><FontAwesomeIcon icon={faArrowLeft} /></i>
                    </div>
                    <h5>New Chat</h5>
                </div>
            </div>
            <div className="chat-box chat-box-left">
                <div className="serach-button-tab">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group" style={{ position: 'relative' }}>
                                <input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    placeholder="Search"
                                    value={search}
                                    onChange={handleSearchChange}

                                />
                                <div style={{ position: 'absolute', top: '50%', left: '15px', transform: 'translateY(-50%)', color: 'var(--white)', fontSize: '18px' }}>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                </div>
                                {
                                    search && (<div style={{ position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)', color: 'var(--white)', fontSize: '18px' }}>
                                        <FontAwesomeIcon icon={faXmark} onClick={() => handleSearchChange({ target: { value: '' } })} />
                                    </div>)
                                }
                            </div>
                        </div>
                        <div className="new-group-box">
                            <div className="col-md-12">
                                <div onClick={() => dispatch(setAddGroupDrawer(true, false))} className="new-grp">
                                    <div className="col-2" >
                                        <img src={new_user_add} alt="upload-img" />
                                    </div>
                                    <div className="col-10">
                                        <span className="title">New Group</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-content" id="nav-tabContent">
                    <p className="orange">Contact on Zebra</p>
                    {/* New chat page  */}
                    <div className="tab-pane fade active show" id="nav-User" role="tabpanel" aria-labelledby="nav-User-tab">
                        {UserList && UserList.length > 0 ?
                            <nav className="user-chat-nav newgroup-box new-chat" id="scrollbar">
                                <div ref={messagesEndRef}></div>
                                {UserList.map((data, index) => (
                                    <div key={index} className="nav-link" >

                                        <div className="user-chat-box" onClick={() => createNewConv(data)}>
                                            <Link className="d-flex align-items-center">
                                                <div className="img-box">
                                                    {data.profile_pic === null ?
                                                        <img
                                                            src={user}
                                                            alt=""
                                                        />
                                                        : <img
                                                            src={data.profile_pic}
                                                            alt=""
                                                        />
                                                    }
                                                </div>
                                                <div className="user-info text-start">
                                                    <h5>{data.contact_name}</h5>
                                                </div>
                                            </Link>
                                        </div>

                                    </div>
                                ))}

                            </nav>
                            : <div className="empty-message-box">
                                <img src={empty_msg_img} alt="" />
                                <p className="empty-message">No Contact Found</p>
                            </div>}</div>
                </div>
            </div>
        </div >
    )
}

export default NewChat