import React, { createRef, useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faClock, faCheck, faCheckDouble, faXmark, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import user from '../../assets/images/user.png'
import emoji from '../../assets/images/emoji-btn.png'
import plus_btn from '../../assets/images/plus-btn.png'
import voice_btn from '../../assets/images/voice-btn-white.png'
import send_btn from '../../assets/images/send.png'
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, sortByCreatedAt, useToast, utcToLocalConvertor, formatTime, utcToLocalConvertorTime, processString } from '../../utils/helpers/CommFun';
import api from '../../services/Api';
import { getMembersList, getUserList, setConvList, setCurrChat, setGroupMembers } from '../../redux/actions/convListActions';
import 'bootstrap/dist/css/bootstrap.min.css';
import { setAddGroupDrawer, setGroupInfoDrawer, setNewChatDrawer, setNewGroupDrawer, setContactInfoDrawer } from '../../redux/actions/drawerActions';
import Picker from '@emoji-mart/react'
import { OnlineStatusContext } from '../../context/OnlineStatusContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import Swal from 'sweetalert2';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import axios from 'axios';
import ContactInfo from './ContactInfo';
import '../../assets/css/drawer.css';
import GroupInfo from './GroupInfo';
import { Modal } from "react-bootstrap";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import empty_msg_img from '../../assets/images/no_message.png'
import AudioMessage from './layout/customaudio'
import moment from "moment";
import play_btn from '../../assets/images/video-play-btn.png'
import data from '@emoji-mart/data';
function Chatwindow({ socket }) {

    const toast = useToast();
    const isOnline = useContext(OnlineStatusContext);
    const navigate = useNavigate();



    const socketUrl = process.env.REACT_APP_SOCKET_URL;
    const { currChat, conversationList } = useSelector(state => state.conversation);

    const userToken = localStorage.getItem('token')
    const userData = JSON.parse(localStorage.getItem('userData')) || null;

    const [message, setMessage] = useState("")
    const [OldMessage, setOldMessage] = useState([])

    const [offlineMessageList, setofflineMessageList] = useState([])
    const [messageId, setMessageId] = useState(0);
    const [currChatData, setcurrChatData] = useState(currChat);
    const [pickerToggler, setPickerToggler] = useState(false);
    const [star_messages, setStarMessages] = useState([]);
    const [showInputs, setShowInputs] = useState(false);
    const [Isremoved, setIsRemoved] = useState("0");
    const groupMembers = useSelector(state => state.conversation.groupMembers);

    //All Media
    const [audioDuration, setaudioDuration] = useState('');
    const [recording, setRecording] = useState(false);
    const [File, setFile] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0)
    const [ContentType, setContentType] = useState(1);
    const [uploadToken, setUploadToken] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [Uploading, setUploading] = useState(0);
    const audioChunks = useRef([]);

    const [currentAudioRecorder, setCurrentAudioRecorder] = useState(null);
    useEffect(() => {
        setCurrentAudioRecorder(currChat.conv_id)
    })

    const audioBlob = useRef(null);
    const [AudioURL, setAudioURL] = useState("");
    var mediaRecorderRef = useRef(null);
    var mediaRecorder = useRef(null);
    const [time, setTime] = useState({ minutes: 0, seconds: 0 });

    var ContactInfoDrawerRef = useRef(null);
    var GroupInfoInfoDrawerRef = useRef(null);
    const { isContactInfoOpen, isGroupInfoOpen } = useSelector(state => state.drawer);
    //reverse pagintaion
    const [page, setPage] = useState(1);
    const [Loading, setLoading] = useState(false);
    const [messageEnd, setMessageEnd] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    //media preview
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [mediaType, setMediaType] = useState('');
    const [mediaSrc, setMediaSrc] = useState('');

    //recording 
    const [recordingFooter, setRecordingFooter] = useState(false);

    //no message found
    const [listMessage, setListMessage] = useState("");

    //progressBar
    const [toggleProgressBar, setToggleProgressBar] = useState(false);
    const [uploadingFiles, setUploadingFiles] = useState([]);

    const handleMediaClick1 = (src, type) => {
        setMediaSrc(src);
        setMediaType(type);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setMediaSrc('');
        setMediaType('');
    };

    //emoji
    const pickerRef = useRef(null);
    const emojiRef = useRef(null);

    // For Smooth Scrolling
    const messagesEndRef = createRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView();
    };

    const dispatch = useDispatch();

    // const socket = useContext(SocketContext);


    useEffect(() => {
        if (OldMessage?.length > 0) {
            const updatedCurrChat = {
                ...currChat, messageList: OldMessage,
            };
            const updatedConversationList = conversationList.map(conv =>
                conv.conv_id === currChat.conv_id ? updatedCurrChat : conv
            );

            dispatch(setConvList(updatedConversationList))
        }
    }, [OldMessage])

    useEffect(() => {

        setOldMessage(currChat.messageList);

        if (currChat && currChat.messageList && currChat.messageList.length > 0) {
            onSend_message(currChat.messageList[0]);
        }


        //scroll down 
        scrollToBottom();
        setPage(1)

        if (currChat.conv_type == 2) {
            dispatch(getMembersList(currChat.conv_id, navigate));
        } else {
            dispatch(setGroupMembers([]));
        }
    }, [currChat, dispatch])

    useEffect(() => {
        setOldMessage(currChat.messageList);

    }, [currChat.messageList])

    // read all messages socket
    useEffect(() => {
        const eventData = {
            Authorization: "Bearer " + userToken,
            conv_id: `${currChat.conv_id}`,
        };
        if (socket) {
            socket.emit("mark_all_read", eventData);
        }

    }, [])

    //Report user
    const reportConversation = () => {
        Swal.fire({
            title: "Report User",
            text: "Are you sure you want to Report?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#e20000",
            confirmButtonText: "Report",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const body = {
                        conv_id: currChat.conv_id,
                    }
                    const response = await api.post(`/report_conversation`, body, userToken);
                    if (response.code == 200) {
                        toast.fire({
                            icon: "success",
                            title: response.message,
                        });
                    } else {
                        console.error(response.message);
                    }
                } catch (error) {
                    console.error("Error fetching messages:", error);
                }
            }
        });
    }

    // Fetch messages
    const oldMessageList = async (pageToFetch = 1) => {
        try {
            const response = await api.get(`/web_old_messages_list?conv_id=${currChat.conv_id}&message_id=${messageId}&size=10&page=${pageToFetch}`, userToken);
            if (response && response.code == 200 && response.result && response.result.length > 0) {
                setMessageEnd(false);
                setHasMore(true);
                setOldMessage((prevMessages) => {
                    const newMessages = response.result.filter(
                        newMessage => !prevMessages.some(existingMessage => existingMessage.message_id === newMessage.message_id)
                    );
                    const updatedMessages = [...prevMessages, ...newMessages];
                    return updatedMessages;
                });

            }
            else {
                //  setOldMessage([]);
                setHasMore(false);
                setListMessage(response.message);
                // setListMessage(response.message);

            }
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
        setLoading(false);
    };



    //on message receive socket
    const onSend_message = async (data) => {


        // scrollToBottom();
        if (data.message_id) {
            if (data.conv_id == currChat.conv_id && data.sender_id !== userData.id) {
                const updateMessageStatus = {
                    Authorization: "Bearer " + userToken,
                    message_ids: data.message_id,
                    status: "3"
                };
                socket.emit("update_message_status", updateMessageStatus);
            }
        }
    };

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            if (timeoutId) clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(null, args);
            }, delay);
        };
    };


    // Message send socket
    const sendMessage = async () => {
        if (currChat.conv_id === null) {
            let newConvList = [currChat.user_id, userData.id];
            const body = {
                Authorization: "Bearer " + userToken,
                user_id: userData.id,
                participant_ids: newConvList.toString(),
                conv_client_id: Date.now(),
            };
            if (socket) {
                try {
                    const data = await socket.emit("create_conversation", body);
                    dispatch(setNewChatDrawer(false))
                    return
                } catch (error) {
                    console.error("conversation not created", error)
                }
            } else {
                console.error("socket not ready error")
            }
        }
        if (currChat.is_block == 1) {
            unblock();
            return;
        }
        if (!isEmpty(message))
            if (!isEmpty(message)) {

                const newTime = new Date().getTime()
                const localMessages = {
                    sender_id: userData.id,
                    conv_id: currChat.conv_id,
                    content: message,
                    content_type: "1",
                    created_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
                    message_status: "0",
                    client_id: newTime,
                }
                setOldMessage((prevList = []) => [localMessages, ...prevList]);


                const messageData = {
                    Authorization: "Bearer " + userToken,
                    user_id: userData.id,
                    conv_id: currChat.conv_id,
                    content: message,
                    content_type: 1,
                    client_id: newTime,
                };
                const offlineMessageData = {
                    Authorization: "Bearer " + userToken,
                    user_id: userData.id,
                    sender_id: userData.id,
                    conv_id: currChat.conv_id,
                    content: message,
                    content_type: 1,
                    client_id: new Date().getTime(),
                    created_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
                };

                if (socket && isOnline) {
                    try {

                        await socket.emit("send_message", messageData);
                        scrollToBottom();
                        setMessage("");
                    } catch (error) {
                        console.error("error", error)
                    }
                } else {
                    console.log("message was not send ");

                    setofflineMessageList((prevList) => [...prevList, offlineMessageData]);
                    setMessage("");
                }
            }
    };

    useEffect(() => {

        if (!Array.isArray(offlineMessageList) && offlineMessageList.length === 0) {
            console.error('offlineMessageList is not an array:', offlineMessageList);
            return;
        }
        localStorage.setItem("offlineMessageList", JSON.stringify(offlineMessageList));

        setOldMessage((prevMessages) => {
            if (!Array.isArray(prevMessages)) {
                console.error("prevMessages is not an array:", prevMessages);
                return [];
            }
            const newMessages = offlineMessageList.filter(
                newMessage => !prevMessages.some(existingMessage => existingMessage.client_id === newMessage.client_id)
            );
            return [...newMessages, ...prevMessages];
        });
    }, [offlineMessageList]);

    useEffect(() => {
        if (isOnline && socket && offlineMessageList.length > 0) {
            const reversedOfflineMessages = offlineMessageList.slice().reverse();
            reversedOfflineMessages.forEach(async (offlineMessage) => {
                try {
                    scrollToBottom()
                    await socket.emit("send_message", offlineMessage);
                    localStorage.removeItem("offlineMessageList");
                    setofflineMessageList([]);
                    setOldMessage([]);
                } catch (error) {
                    console.error("Failed to send offline message:", offlineMessage, error);
                }
            });
            setofflineMessageList([]);
        }
    }, [isOnline, socket]);



// const enqueueMessage = (messageData) => {
//     setofflineMessageList((prevList) => {
//         const filteredList = prevList.filter(item => item.client_id !== messageData.client_id);
//         const newList = [...filteredList, messageData];
//         return newList.sort((a, b) => a.client_id - b.client_id);
//     });
// };

// console.log("offlineMessageList--",offlineMessageList)
// const processQueue = async () => {
//     if (isOnline && socket && offlineMessageList.length > 0) {
//         const newQueue = [];
//         const sentMessageIds = new Set();
//         const reversedOfflineMessages = offlineMessageList.slice().reverse();
//         const processMessages = async () => {
//             for (const offlineMessage of reversedOfflineMessages) {
//                 if (!sentMessageIds.has(offlineMessage.client_id)) {
//                     try {
//                         scrollToBottom();
//                         await socket.emit("send_message", offlineMessage);
//                         sentMessageIds.add(offlineMessage.client_id);
//                     } catch (error) {
//                         console.error("Failed to send offline message:", offlineMessage, error);
//                         newQueue.push(offlineMessage);
//                     }
//                 }
//             }
//             localStorage.setItem("offlineMessageList", JSON.stringify(newQueue));
//             setofflineMessageList(newQueue);
//             setOldMessage((prevMessages) => prevMessages.filter(msg => !sentMessageIds.has(msg.client_id)));
//         };
//         processMessages();
//     }
// };

// const sendMessage = async () => {
//     if (currChat.conv_id === null) {
//         let newConvList = [currChat.user_id, userData.id];
//         const body = {
//             Authorization: "Bearer " + userToken,
//             user_id: userData.id,
//             participant_ids: newConvList.toString(),
//             conv_client_id: Date.now(),
//         };
//         if (socket) {
//             try {
//                 await socket.emit("create_conversation", body);
//                 dispatch(setNewChatDrawer(false));
//                 return;
//             } catch (error) {
//                 console.error("Conversation not created", error);
//             }
//         } else {
//             console.error("Socket not ready");
//         }
//     }

//     if (currChat.is_block === 1) {
//         unblock();
//         return;
//     }

//     if (!isEmpty(message)) {
//         const newTime = Date.now();
//         const localMessages = {
//             sender_id: userData.id,
//             conv_id: currChat.conv_id,
//             content: message,
//             content_type: "1",
//             created_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
//             message_status: "0",
//             client_id: newTime,
//         };

//         setOldMessage((prevList) => [localMessages, ...prevList]);

//         const messageData = {
//             Authorization: "Bearer " + userToken,
//             user_id: userData.id,
//             conv_id: currChat.conv_id,
//             content: message,
//             content_type: 1,
//             client_id: newTime,
//         };

//         const offlineMessageData = {
//             ...messageData,
//             created_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
//         };

//         if (socket && isOnline) {
//             try {
//                 await socket.emit("send_message", messageData);
//                 scrollToBottom();
//                 setMessage("");
//             } catch (error) {
//                 console.error("Error sending message", error);
//                 enqueueMessage(offlineMessageData);
//             }
//         } else {
//             console.log("Message was not sent");
//             enqueueMessage(offlineMessageData);
//             setMessage("");
//         }
//     }
// };

// useEffect(() => {
//     localStorage.setItem("offlineMessageList", JSON.stringify(offlineMessageList));
//     setOldMessage((prevMessages) => {
//         const newMessages = offlineMessageList.filter(
//             newMessage => !prevMessages.some(existingMessage => existingMessage.client_id === newMessage.client_id)
//         );
//         return [...newMessages, ...prevMessages];
//     });
// }, [offlineMessageList]);

// useEffect(() => {
//     const storedMessages = JSON.parse(localStorage.getItem("offlineMessageList") || "[]");
//         setofflineMessageList(storedMessages.sort((a, b) => a.client_id - b.client_id));
// }, []);

// useEffect(() => {
//     processQueue();
// }, [isOnline, socket,offlineMessageList]);

    const onget_message_status = async (data) => {
        if (data.sender_id !== userData.id) {
            setOldMessage((prevMessages) => {
                if (!Array.isArray(prevMessages)) {
                    console.error("prevMessages is not an array:", prevMessages);
                    return;
                }
                const updatedMessages = prevMessages.map(message => {
                    if (message.message_id === data.message_id && message.message_status < data.message_status) {
                        return { ...message, message_status: data.message_status };
                    }
                    return message;
                });
                return updatedMessages;
            });
        }
    };


    const reload = (data) => {
        dispatch(setAddGroupDrawer(false, false));
        dispatch(setNewGroupDrawer(false));
        dispatch(setNewChatDrawer(false));
    }

    const removeMessage = (data) => {
        setOldMessage((prev) => {
            if (!Array.isArray(prev)) {
                console.error("State `prev` is not an array:", prev);
                return prev;
            }
            const updatedMessages = prev.filter((message) => message.message_id !== data[0].message_id);
            return updatedMessages;
        });
    };

    //On Star Message Markd 
    const onmessageMarked = (socketResponse) => {
        setOldMessage((prev) => {
            // Extract message IDs from the socket response
            const messageIdsToMark = socketResponse.map(item => item.message_id);

            // Update the messages
            const updatedMessages = prev.map((message) => {
                if (messageIdsToMark.includes(`${message.message_id}`)) {
                    return { ...message, is_star: 1 };
                }
                return message;
            });
            return updatedMessages;
        });
    }

    //On Star Message UnMarkd
    const onmessageUnMarked = (socketResponse) => {
        setOldMessage((prev) => {
            // Extract message IDs from the socket response
            const messageIdsToMark = socketResponse.map(item => item.message_id);

            // Update the messages
            const updatedMessages = prev.map((message) => {
                if (messageIdsToMark.includes(message.message_id)) {
                    return { ...message, is_star: 0 };
                }
                return message;
            });
            return updatedMessages;
        });
    }

    //All chat read
    const allChatRead = (socketResponse) => {
        setOldMessage((prev) => {
            const updatedMessages = prev.map((message) => {
                return { ...message, message_status: "3" };
            });
            return updatedMessages;
        });
    }

    // `Socket events 
    useEffect(() => {
        if (socket) {
            socket.on("all_chat_read", allChatRead);
            socket.on("update_message_status", onget_message_status);
            socket.on("add_member", reload);
            // socket.on("remove_member", remove_member);
            socket.on("create_group", reload);
            socket.on("delete_messages", removeMessage);
            socket.on("star_message_marked", onmessageMarked);
            socket.on("star_message_unmarked", onmessageUnMarked);
            // socket.on("create_conversation", setNewConversation);
            return () => {
                socket.off("all_chat_read", allChatRead);
                socket.off("update_message_status", onget_message_status);
                socket.off("create_group", reload);
                socket.off("add_member", reload);
                // socket.off("remove_member", remove_member);
                socket.off("delete_messages", removeMessage);
                socket.off("star_message_marked", onmessageMarked);
                socket.off("star_message_unmarked", onmessageUnMarked);
                // socket.off("create_conversation", setNewConversation);
            };
        }
    }, [socket]);

    //Exit Group
    const exitGroup = async () => {
        try {
            const body = {
                conv_id: currChat.conv_id,
                user_id: userData.id,
            }
            const response = await api.post(`/remove_member`, body, userToken);

            if (response.code == 200) {
                toast.fire({
                    icon: "success",
                    title: response.message,
                });
                // setIsRemoved("1");
                let CurrChat = {
                    ...currChat,
                    is_removed: "1"
                }
                dispatch(setCurrChat(CurrChat))
                let updatedConversationList = conversationList.map(conv =>
                    conv.conv_id === data.conv_id ? CurrChat : conv
                );
                dispatch(setConvList([...updatedConversationList]));
            } else {
                toast.fire({
                    icon: "error",
                    title: response.message,
                });
                // setIsRemoved("0");
            }
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
    }

    const debouncedSendMessage = debounce(sendMessage, 100);

    const handleSendMessage = (e) => {
        e.preventDefault();
        debouncedSendMessage();
    };

    
    const handleEmojiSelect = (emoji) => {
        setMessage((prev) => prev + emoji.native);
    };

    // handle star Messages
    const handleStarMessage = async (messageId, convId, is_star) => {

        // Convert is_star to a string if needed
        const newStarStatus = is_star ? '1' : '0';

        // Update the star status in the state
        setStarMessages(prevState => {
            // Check if the message already exists
            const existingIndex = prevState.findIndex(msg => msg.message_id === messageId && msg.conv_id === convId);

            if (existingIndex !== -1) {
                // Update existing message
                const updatedMessages = [...prevState];
                updatedMessages[existingIndex] = { conv_id: `${convId}`, message_id: `${messageId}`, is_star: newStarStatus };
                return updatedMessages;
            } else {
                // Add new message
                return [
                    ...prevState,
                    {
                        conv_id: `${convId}`,
                        message_id: `${messageId}`,
                        is_star: newStarStatus
                    }
                ];
            }
        });
    };

    const handleStarSocket = async () => {
        const is_star_status = star_messages.length > 0 ? star_messages[0].is_star : '0';
        const starStatus = is_star_status
        const starMessageData = {
            Authorization: "Bearer " + userToken,
            star_messages: star_messages.map(({ conv_id, message_id }) => ({
                conv_id,
                message_id
            })),
            star_status: starStatus
        };
        await socket.emit('star_unstar_message', starMessageData);
        setStarMessages([]);
    };

    //Handle Delete Message
    const handleDeleteMessage = async (messageId, convId) => {
        const delete_messages = [{
            conv_id: convId,
            message_id: messageId
        }]
        console.log("delete_messages-----", delete_messages)
        const deleteMessageData = {
            Authorization: "Bearer " + userToken,
            delete_messages,
        };
        try {
            await socket.emit('delete_messages', deleteMessageData);
            const updatedCurrChat = {
                ...currChat,
                messageList: currChat.messageList.filter(message =>
                    !(message.conv_id == convId && message.message_id === messageId)
                )
            };
            dispatch(setCurrChat(updatedCurrChat));
        } catch (error) {
            console.error("Error deleting message:", error);
        }



    }

    //Handle Copy Message
    const handleCopyMessage = (content) => {
        navigator.clipboard.writeText(content)
            .then(() => {
                toast.fire({
                    icon: "success",
                    title: " Message copied! ",
                });
            })
            .catch((err) => {
                toast.fire({
                    icon: "error",
                    title: err,
                });
            });
    };

    useEffect(() => {
        if (star_messages.length > 0) {
            handleStarSocket();
        }
    }, [star_messages]);

    //All media Upload 
    const handleMediaClick = () => {
        if (currChat.is_block == 1) {
            unblock();
            return;
        }
        const fileInput = document.createElement('input');
        fileInput.setAttribute('type', 'file');
        fileInput.setAttribute('accept', 'image/*, video/*');
        fileInput.style.display = 'none';
        fileInput.addEventListener('change', handleFileChange);
        document.body.appendChild(fileInput);
        fileInput.click();
        document.body.removeChild(fileInput);
    };

    const updateMessageProgress = (clientId, progress) => {
        setOldMessage(prevList =>
            prevList.map(message =>
                message.client_id === clientId ? { ...message, progress } : message
            )
        );

        setUploadingFiles(prevFiles => {
            ;
            const updatedFiles = new Map(prevFiles)
            updatedFiles.set(clientId, { progress });
            return updatedFiles;
        });
    };

    const handleFileChange = (event) => {


        const file = event.target.files[0];
        const imgUrl = URL.createObjectURL(file);
        const clientId = new Date().getTime();

        const ContentType = file.type.startsWith('image/') ? 2
            : file.type.startsWith('audio/') ? 3
                : file.type.startsWith('video/') ? 4
                    : null;

        if (!ContentType) {
            console.error("Unsupported file type");
            return;
        }

        // Set initial state
        setToggleProgressBar(true);
        setFile(file);

        // Create local message object
        let localMessages = {
            sender_id: userData.id,
            conv_id: currChat.conv_id,
            content: imgUrl,
            content_type: ContentType,
            created_at: new Date().toLocaleString(),
            message_status: "0",
            client_id: clientId,
            thumbnail: null,
            progress: 0,
        };

        // Check if file is a video
        if (ContentType === 4) {
            // Create a video element
            const video = document.createElement('video');
            video.src = imgUrl;
            video.muted = true;
            video.play();

            // Wait for the video to load and play
            video.addEventListener('canplay', () => {
                // Create a canvas element
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const ctx = canvas.getContext('2d');

                // Draw the video frame on the canvas
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

                // Get the thumbnail as a data URL
                const thumbnail = canvas.toDataURL();

                // Update the local message object with the thumbnail
                localMessages = { ...localMessages, thumbnail };

                // Clean up
                video.pause();
                video.src = '';
                canvas.remove();

                // Update the message list with the new object
                setOldMessage(prevList => [localMessages, ...prevList]);
            });
        } else {
            setOldMessage(prevList => [localMessages, ...prevList]);
        }

        // Initialize file upload progress tracking
        setUploadingFiles(prevFiles => new Map(prevFiles).set(clientId, { progress: 0 }));

        // Create FormData for the request
        const formData = new FormData();
        formData.append('conv_id', currChat.conv_id);
        formData.append('content_type', ContentType);
        formData.append('client_id', clientId);
        formData.append('file', file);

        // Start file upload
        axios.post(baseUrl + '/upload_media', formData, {
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                updateMessageProgress(clientId, progress);
            }
        })
            .then(response => {
                setToggleProgressBar(false);
                setUploadingFiles(prevFiles => {
                    const updatedFiles = new Map(prevFiles);
                    updatedFiles.delete(clientId); // Remove from progress tracking
                    return updatedFiles;
                });
                // Handle the response from the server
            })
            .catch(error => {
                console.error("Error uploading", error);
                setUploadingFiles(prevFiles => {
                    const updatedFiles = new Map(prevFiles);
                    updatedFiles.delete(clientId); // Remove from progress tracking
                    return updatedFiles;
                });
            });
    };
    const [audioCurrChat, setAudioCurrChat] = useState(null)
    const startRecording = (event) => {

        if (currChat.is_block == 1) {
            unblock();
            return;
        }
        setAudioCurrChat(currChat.conv_id)
        const mimeTypes = ["audio/mp4", "audio/webm"].filter((type) =>
            MediaRecorder.isTypeSupported(type)
        );
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
                mediaRecorder = new MediaRecorder(stream, { mimeType: mimeTypes[0] }
                );
                mediaRecorderRef.current = mediaRecorder;
                audioChunks.current = []; // Clear previous chunks


                mediaRecorder.ondataavailable = (event) => {
                    audioChunks.current.push(event.data);
                };

                mediaRecorder.onstop = async () => {
                    const audioBlobs = new Blob(audioChunks.current, {
                        type: "audio/mp3",
                    });
                    audioBlob.current = audioBlobs
                    setAudioURL(URL.createObjectURL(audioBlobs));
                    // await setAudioBlob(audioBlobs)
                    // audioChunks.current = [];
                };

                mediaRecorder.start();
                setRecording(true);
                setRecordingFooter(true);

                setContentType(2);
            })
            .catch((error) => {
                console.error("Error accessing microphone:", error);
            });
    }

    const stopRecording = () => {
        if (mediaRecorderRef && mediaRecorderRef.current.state === "recording") {
            mediaRecorderRef.current.stop();
            setRecording(false);
            setRecordingFooter(false)

            // setAudioBlob([])
            // setContentType(1);
            setTime({ minutes: 0, seconds: 0 });
        }
    };

    useEffect(() => {
        if (recording) {
            stopRecording();
        }
    }, [currChat.conv_id]);

    const baseUrl = process.env.REACT_APP_BASE_URL;

    const handleAudioUpload = () => {
        if (mediaRecorderRef && mediaRecorderRef.current.state === "recording") {
            mediaRecorderRef.current.stop();
            setRecording(false);
            setRecordingFooter(false)
            setTime({ minutes: 0, seconds: 0 });

            setTimeout(() => {
                try {
                    let audiourl = URL.createObjectURL(audioBlob.current)
                    const clientId = new Date().getTime();
                    //  let audioBlob = new Blob([audioBlob.current], { type: "audio/mp3" });

                    const getClientId = new Date().getTime()
                    const localMessages = {
                        sender_id: userData.id,
                        conv_id: currChat.conv_id,
                        content: audiourl,
                        content_type: 3,
                        created_at: new Date().toLocaleString(),
                        message_status: "0",
                        client_id: getClientId,
                        progress: 0,
                    }

                    setOldMessage((prevList) => [localMessages, ...prevList]);
                    setToggleProgressBar(true);
                    // Initialize file upload progress tracking
                    setUploadingFiles(prevFiles => new Map(prevFiles).set(clientId, { progress: 0 }));

                    // set audio duration 
                    const audio = new Audio(audiourl);
                    audio.onloadedmetadata = () => {
                        const audioDuration = new Date(audio.duration * 1000).toISOString().substr(14, 5);


                        const formData = new FormData();

                        formData.append("content_type", "3");
                        formData.append("conv_id", currChat.conv_id);
                        formData.append("client_id", getClientId);
                        formData.append("file", audioBlob.current);
                        formData.append("audio_length", audioDuration);

                        axios.post(baseUrl + '/upload_media', formData, {
                            headers: {
                                'Authorization': `Bearer ${userToken}`,
                                'Content-Type': 'multipart/form-data',
                            },
                            onUploadProgress: (progressEvent) => {
                                const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                                updateMessageProgress(clientId, progress);
                            }
                        })
                            .then(response => {
                                setToggleProgressBar(false);
                                setUploadingFiles(prevFiles => {
                                    const updatedFiles = new Map(prevFiles);
                                    updatedFiles.delete(clientId); // Remove from progress tracking
                                    return updatedFiles;
                                });
                            })
                            .catch(error => {
                                console.error("Error uploading", error);
                                setUploadingFiles(prevFiles => {
                                    const updatedFiles = new Map(prevFiles);
                                    updatedFiles.delete(clientId); // Remove from progress tracking
                                    return updatedFiles;
                                });
                            });
                    }
                } catch (error) {
                    console.error("Error uploading", error);
                    setUploadProgress(0);
                }

            }, 100);
        }
    };

    const formatcounterTime = (value) => {
        return value.toString().padStart(2, '0');
    };

    useEffect(() => {
        let interval = null;
        if (recording) {
            interval = setInterval(() => {
                setTime(prevTime => {
                    let { minutes, seconds } = prevTime;
                    seconds += 1;
                    if (seconds === 60) {
                        seconds = 0;
                        minutes += 1;
                    }
                    return { minutes, seconds };
                });
            }, 1000);
        } else if (!recording && time.seconds !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [recording, time.seconds]);

    const onpaginate = () => {
        setLoading(true);
        updatePage(1);
    };
    const updatePage = async (flag) => {
        if (flag === 1) {
            setPage((prevPage) => {
                const pageToFetch = prevPage + 1;
                oldMessageList(pageToFetch);
                return pageToFetch;
            });
        }
    }
    const getImageSrc = () => {
        if (currChat.conv_type === "2") {
            return currChat.group_icon || user;
        }
        if (currChat.conv_type === "1") {
            return currChat.profile_pic || user;
        }
        if (currChat.conv_id === null) {
            return currChat.profile_pic || user;
        }
        if (currChat.group_icon === null || currChat.profile_icon === null) {
            return user;
        }
        // Default fallback
        return user;
    };

    //emoji closing
    const handleClickOutside = (event) => {
        if (
            pickerRef.current &&
            !pickerRef.current.contains(event.target) &&
            !emojiRef.current.contains(event.target)
        ) {
            setPickerToggler(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    //For close contact and group drawer
    const handleClickOutside2 = (event) => {
        if (ContactInfoDrawerRef.current && !ContactInfoDrawerRef.current.contains(event.target) && !GroupInfoInfoDrawerRef.current.contains(event.target)) {
            dispatch(setContactInfoDrawer(false));
            dispatch(setGroupInfoDrawer(false));
        }
    };

    useEffect(() => {
        if (isContactInfoOpen || isGroupInfoOpen) {
            document.addEventListener('mousedown', handleClickOutside2);
        } else {
            document.removeEventListener('mousedown', handleClickOutside2);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside2);
        };
    }, [isContactInfoOpen, isGroupInfoOpen]);

    // custom audio functionality
    const [playingMessageId, setPlayingMessageId] = useState(null);
    const [progresses, setProgresses] = useState({});
    const [currentTimes, setCurrentTimes] = useState({});
    const audioRefs = useRef({});

    const handlePlayPause = (messageId) => {
        if (playingMessageId && audioRefs.current[playingMessageId]) {
            audioRefs.current[playingMessageId].pause();
        }

        if (messageId === playingMessageId) {
            setPlayingMessageId(null);
        } else {
            setPlayingMessageId(messageId);
            if (audioRefs.current[messageId]) {
                audioRefs.current[messageId].play();
            }
        }
    };

    const handleTimeUpdate = (messageId, currentTime, duration) => {
        setCurrentTimes((prev) => ({ ...prev, [messageId]: currentTime }));
        setProgresses((prev) => ({ ...prev, [messageId]: (currentTime / duration) * 100 }));
    };

    const handleSeek = (messageId, newTime) => {
        if (audioRefs.current[messageId]) {
            const audio = audioRefs.current[messageId];
            audio.currentTime = newTime;
            setCurrentTimes((prev) => ({ ...prev, [messageId]: newTime }));
        }
    };

    const isLink = (text) => {
        const urlPattern = /^(https?:\/\/[^\s/$.?#].[^\s]*)$/i;
        return urlPattern.test(text);
    };

    // block unblock swal
    const unblock = async () => {
        Swal.fire({
            title: "Unblock",
            text: "Are you sure want to unblock user?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Unblock",
        }).then((result) => {
            if (result.isConfirmed) {
                blockToggler()
            }
        });
    };

    const blockToggler = async () => {
        try {
            const block = currChat.is_block == 0 ? 1 : 0;
            const body = {
                conv_id: currChat.conv_id,
                is_block: block
            }
            const response = await api.post(`/user_block`, body, userToken);

            if (response.code == 200) {
                toast.fire({ icon: "success", title: response.message });
                const newCurrChat = {
                    ...currChat,
                    is_block: block
                }
                dispatch(setCurrChat(newCurrChat));
                let updatedConversationList = conversationList.map(conv =>
                    conv.conv_id === data.conv_id ? newCurrChat : conv
                );
                dispatch(setConvList([...updatedConversationList]));
            }
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
    }
    console.log("OldMessage----", OldMessage)

    return (
        <div className="col-md-7 col-lg-8 col-xxl-9 p-0">
            <div className="toggle-sidebar-section d-flex">
                {Object.keys(currChat).length !== 0 ?
                    <div className="chat-box-right">
                        <div className="tab-content" id="nav-tabContent">
                            <div
                                className="tab-pane fade active show">
                                <div className="content">
                                    <div className="contact-profile card-header" onClick={() => currChat.conv_type == 2 ? dispatch(setGroupInfoDrawer(true)) : dispatch(setContactInfoDrawer(true))} >
                                        <div className='d-flex' >
                                            <img src={getImageSrc()} />
                                            <div className="user-info" style={{ display: 'flex', alignItems: 'center', width: '200px' }}>
                                                <h5>{currChat.conv_type == 2 ? currChat.group_name : currChat.username || currChat.contact_name}</h5>
                                            </div>
                                        </div>
                                        <div className="call-setting-tab">


                                        </div>
                                    </div>

                                    {Loading && (
                                        <span className="loader"></span>
                                    )}
                                    <div
                                        className="card-body msg_card_body msg_card_body_2 "
                                        id="scrollableDiv1"
                                        style={{
                                            display: "flex",
                                            flexDirection: "column-reverse",
                                            overflow: 'auto',
                                        }}
                                    >
                                        <div key={currChat.conv_id} ref={messagesEndRef}></div>
                                        <InfiniteScroll
                                            dataLength={OldMessage?.length || 0}
                                            next={onpaginate}
                                            //To put endMessage and loader to the top.
                                            inverse={true}
                                            hasMore={true}
                                            scrollableTarget="scrollableDiv1"

                                            endMessage={
                                                <div className="text-center mt-2">
                                                    <p>You have reached the end of the conversation</p>
                                                </div>
                                            }
                                            style={{ display: 'flex', flexDirection: 'column-reverse' }}
                                        >


                                            {(OldMessage && OldMessage.length > 0) ?
                                                OldMessage.map((message, index) =>
                                                    message.sender_id == userData.id ?
                                                        <div key={index} className="d-flex justify-content-end">
                                                            <div className="message message-send">
                                                                {
                                                                    message.content_type == 1 ? (
                                                                        <div className="msg_cotainer_send">
                                                                            <div>
                                                                                {isLink(message.content) ? (
                                                                                    <a href={processString(message.content)} style={{ color: 'blue', textDecoration: 'underline', overflowWrap: 'anywhere',textAlign: 'justify' }} target="_blank" rel="noopener noreferrer">
                                                                                        {processString(message.content)}
                                                                                    </a>
                                                                                ) : (
                                                                                    <span>  {processString(message.content)}</span>
                                                                                )}
                                                                                <div className="dropdown message-dropdown ">
                                                                                    <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                                                                        aria-expanded="false"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <g clipPath="url(#clip0_1343_2)">
                                                                                                <path d="M6.46824 9.33353L11.8083 3.99338C11.9319 3.86987 12 3.70499 12 3.52919C12 3.35339 11.9319 3.18851 11.8083 3.065L11.4151 2.67173C11.1589 2.41583 10.7425 2.41583 10.4867 2.67173L6.00249 7.15599L1.51326 2.66676C1.38965 2.54325 1.22487 2.47505 1.04916 2.47505C0.873261 2.47505 0.708483 2.54325 0.584776 2.66676L0.191707 3.06002C0.068098 3.18363 9.15499e-07 3.34841 9.07814e-07 3.52421C9.00129e-07 3.70002 0.068098 3.86489 0.191707 3.98841L5.53664 9.33353C5.66064 9.45734 5.8262 9.52534 6.0022 9.52495C6.17888 9.52534 6.34434 9.45734 6.46824 9.33353Z" fill="#1F2128"></path>
                                                                                            </g>
                                                                                            <defs>
                                                                                                <clipPath id="clip0_1343_2">
                                                                                                    <rect width="12" height="12" fill="white" transform="translate(12) rotate(90)"></rect>
                                                                                                </clipPath>
                                                                                            </defs>
                                                                                        </svg></span>
                                                                                    <ul className="dropdown-menu">
                                                                                        <li><Link className="dropdown-item" onClick={() => handleCopyMessage(message.content)}>Copy message</Link></li>
                                                                                        {message.is_star === 1 ? (
                                                                                            <li>
                                                                                                <Link className="dropdown-item" onClick={() => handleStarMessage(message.message_id, message.conv_id, 0)}>
                                                                                                    Unstar message
                                                                                                </Link>
                                                                                            </li>
                                                                                        ) : (
                                                                                            <li>
                                                                                                <Link className="dropdown-item" onClick={() => handleStarMessage(message.message_id, message.conv_id, 1)}>
                                                                                                    Star message
                                                                                                </Link>
                                                                                            </li>
                                                                                        )}
                                                                                        <li><Link className="dropdown-item" onClick={() => handleDeleteMessage(message.message_id, message.conv_id)}>Delete message</Link></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : message.content_type == 2 ? (
                                                                        <div className="msg_cotainer_send img_cont_msg_send position-relative">
                                                                            {toggleProgressBar && OldMessage.map((msg) => {
                                                                                if (msg.client_id === message.client_id) {
                                                                                    const progressInfo = uploadingFiles.get(msg.client_id);

                                                                                    return progressInfo ? (
                                                                                        <div key={msg.client_id} className='media-progressbar'>
                                                                                            <CircularProgressbar
                                                                                                value={progressInfo.progress}
                                                                                                text={`${progressInfo.progress}%`}
                                                                                            />
                                                                                        </div>
                                                                                    ) : null;
                                                                                }
                                                                                return null;
                                                                            })}
                                                                            <img
                                                                                // height="145" width="245" // Uncomment if you need to set dimensions
                                                                                src={message.content}
                                                                                onClick={() => handleMediaClick1(message.content, 'image')}
                                                                                alt="Message content"
                                                                            />

                                                                            <div className="dropdown message-dropdown">
                                                                                <span
                                                                                    className="dropdown-toggle"
                                                                                    type="button"
                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-expanded="false"
                                                                                >
                                                                                    <svg
                                                                                        width="12"
                                                                                        height="12"
                                                                                        viewBox="0 0 12 12"
                                                                                        fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                    >
                                                                                        <g clipPath="url(#clip0_1343_2)">
                                                                                            <path
                                                                                                d="M6.46824 9.33353L11.8083 3.99338C11.9319 3.86987 12 3.70499 12 3.52919C12 3.35339 11.9319 3.18851 11.8083 3.065L11.4151 2.67173C11.1589 2.41583 10.7425 2.41583 10.4867 2.67173L6.00249 7.15599L1.51326 2.66676C1.38965 2.54325 1.22487 2.47505 1.04916 2.47505C0.873261 2.47505 0.708483 2.54325 0.584776 2.66676L0.191707 3.06002C0.068098 3.18363 9.15499e-07 3.34841 9.07814e-07 3.52421C9.00129e-07 3.70002 0.068098 3.86489 0.191707 3.98841L5.53664 9.33353C5.66064 9.45734 5.8262 9.52534 6.0022 9.52495C6.17888 9.52534 6.34434 9.45734 6.46824 9.33353Z"
                                                                                                fill="#1F2128"
                                                                                            ></path>
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_1343_2">
                                                                                                <rect
                                                                                                    width="12"
                                                                                                    height="12"
                                                                                                    fill="white"
                                                                                                    transform="translate(12) rotate(90)"
                                                                                                ></rect>
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>
                                                                                </span>
                                                                                <ul className="dropdown-menu dropdown-menu-dark">
                                                                                    <li>
                                                                                        <Link
                                                                                            className="dropdown-item"
                                                                                            onClick={() => handleMediaClick1(message.content, 'image')}
                                                                                        >
                                                                                            View
                                                                                        </Link>
                                                                                    </li>
                                                                                    {message.is_star === 1 ? (
                                                                                        <li>
                                                                                            <Link
                                                                                                className="dropdown-item"
                                                                                                onClick={() => handleStarMessage(message.message_id, message.conv_id, 0)}
                                                                                            >
                                                                                                Unstar message
                                                                                            </Link>
                                                                                        </li>
                                                                                    ) : (
                                                                                        <li>
                                                                                            <Link
                                                                                                className="dropdown-item"
                                                                                                onClick={() => handleStarMessage(message.message_id, message.conv_id, 1)}
                                                                                            >
                                                                                                Star message
                                                                                            </Link>
                                                                                        </li>
                                                                                    )}
                                                                                    <li>
                                                                                        <Link
                                                                                            className="dropdown-item"
                                                                                            onClick={() => handleDeleteMessage(message.message_id, message.conv_id)}
                                                                                        >
                                                                                            Delete message
                                                                                        </Link>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>

                                                                    ) : message.content_type == 3 ? (
                                                                        <div className="msg_cotainer">
                                                                            <div className="dropdown message-dropdown ">
                                                                                <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                                                                    aria-expanded="false"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g clipPath="url(#clip0_1343_2)">
                                                                                            <path d="M6.46824 9.33353L11.8083 3.99338C11.9319 3.86987 12 3.70499 12 3.52919C12 3.35339 11.9319 3.18851 11.8083 3.065L11.4151 2.67173C11.1589 2.41583 10.7425 2.41583 10.4867 2.67173L6.00249 7.15599L1.51326 2.66676C1.38965 2.54325 1.22487 2.47505 1.04916 2.47505C0.873261 2.47505 0.708483 2.54325 0.584776 2.66676L0.191707 3.06002C0.068098 3.18363 9.15499e-07 3.34841 9.07814e-07 3.52421C9.00129e-07 3.70002 0.068098 3.86489 0.191707 3.98841L5.53664 9.33353C5.66064 9.45734 5.8262 9.52534 6.0022 9.52495C6.17888 9.52534 6.34434 9.45734 6.46824 9.33353Z" fill="#1F2128"></path>
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_1343_2">
                                                                                                <rect width="12" height="12" fill="white" transform="translate(12) rotate(90)"></rect>
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg></span>
                                                                                <ul className="dropdown-menu dropdown-menu-dark">
                                                                                    {message.is_star === 1 ? (
                                                                                        <li>
                                                                                            <Link className="dropdown-item" onClick={() => handleStarMessage(message.message_id, message.conv_id, 0)}>
                                                                                                Unstar message
                                                                                            </Link>
                                                                                        </li>
                                                                                    ) : (
                                                                                        <li>
                                                                                            <Link className="dropdown-item" onClick={() => handleStarMessage(message.message_id, message.conv_id, 1)}>
                                                                                                Star message
                                                                                            </Link>
                                                                                        </li>
                                                                                    )}
                                                                                    <li><Link className="dropdown-item" onClick={() => handleDeleteMessage(message.message_id, message.conv_id)}>Delete message</Link></li>
                                                                                </ul>
                                                                            </div>

                                                                            <div className="voice-message-box">
                                                                                {toggleProgressBar && OldMessage.map((msg) => {
                                                                                    if (msg.client_id === message.client_id) {
                                                                                        const progressInfo = uploadingFiles.get(msg.client_id);

                                                                                        return progressInfo ? (
                                                                                            <div key={msg.client_id}>
                                                                                                <CircularProgressbar
                                                                                                    className='audioprogress'
                                                                                                    value={progressInfo.progress}
                                                                                                // text={`${progressInfo.progress}%`}
                                                                                                />
                                                                                            </div>
                                                                                        ) : null;
                                                                                    }
                                                                                    return null;
                                                                                })}

                                                                                <AudioMessage
                                                                                    key={message.message_id}
                                                                                    message={message}
                                                                                    isPlaying={playingMessageId === message.message_id}
                                                                                    progress={progresses[message.message_id] || 0}
                                                                                    currentTime={currentTimes[message.message_id] || 0}
                                                                                    onPlayPause={handlePlayPause}
                                                                                    onSeek={handleSeek}
                                                                                    onTimeUpdate={handleTimeUpdate}
                                                                                    ref={(el) => { audioRefs.current[message.message_id] = el && el.getAudio(); }}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    ) : message.content_type == 4 ? (
                                                                        <div className=" msg_cotainer_send video_cont_send position-relative ">

                                                                            {/* <video controls>
                                                                                <source src={message.content} type="video/mp4" />
                                                                                
                                                                                </video> */}
                                                                            <div className="video-container" >
                                                                                <div className="lightboxContainer">
                                                                                    {toggleProgressBar && OldMessage.map((msg) => {
                                                                                        if (msg.client_id === message.client_id) {
                                                                                            const progressInfo = uploadingFiles.get(msg.client_id);

                                                                                            return progressInfo ? (
                                                                                                <div key={msg.client_id} className='media-progressbar'>
                                                                                                    <CircularProgressbar
                                                                                                        value={progressInfo.progress}
                                                                                                        text={`${progressInfo.progress}%`}
                                                                                                    />
                                                                                                </div>
                                                                                            ) : null;
                                                                                        }
                                                                                        return null;
                                                                                    })}
                                                                                    <img src={message.thumbnail} alt="thumbnail" />
                                                                                    <img src={play_btn} alt="" className='video-play-btn' onClick={() => handleMediaClick1(message.content, 'video')} />

                                                                                </div>
                                                                            </div>
                                                                            <div className="dropdown message-dropdown ">
                                                                                <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                                                                    aria-expanded="false"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g clipPath="url(#clip0_1343_2)">
                                                                                            <path d="M6.46824 9.33353L11.8083 3.99338C11.9319 3.86987 12 3.70499 12 3.52919C12 3.35339 11.9319 3.18851 11.8083 3.065L11.4151 2.67173C11.1589 2.41583 10.7425 2.41583 10.4867 2.67173L6.00249 7.15599L1.51326 2.66676C1.38965 2.54325 1.22487 2.47505 1.04916 2.47505C0.873261 2.47505 0.708483 2.54325 0.584776 2.66676L0.191707 3.06002C0.068098 3.18363 9.15499e-07 3.34841 9.07814e-07 3.52421C9.00129e-07 3.70002 0.068098 3.86489 0.191707 3.98841L5.53664 9.33353C5.66064 9.45734 5.8262 9.52534 6.0022 9.52495C6.17888 9.52534 6.34434 9.45734 6.46824 9.33353Z" fill="#1F2128"></path>
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_1343_2">
                                                                                                <rect width="12" height="12" fill="white" transform="translate(12) rotate(90)"></rect>
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg></span>
                                                                                <ul className="dropdown-menu dropdown-menu-dark">
                                                                                    {/* <li><Link className="dropdown-item" onClick={() => handleCopyMessage(message.content)}>Copy message</Link></li> */}
                                                                                    <li><Link className="dropdown-item" onClick={() => handleMediaClick1(message.content, 'video')}>View message</Link></li>
                                                                                    {message.is_star === 1 ? (
                                                                                        <li>
                                                                                            <Link className="dropdown-item" onClick={() => handleStarMessage(message.message_id, message.conv_id, 0)}>
                                                                                                Unstar message
                                                                                            </Link>
                                                                                        </li>
                                                                                    ) : (
                                                                                        <li>
                                                                                            <Link className="dropdown-item" onClick={() => handleStarMessage(message.message_id, message.conv_id, 1)}>
                                                                                                Star message
                                                                                            </Link>
                                                                                        </li>
                                                                                    )}
                                                                                    <li><Link className="dropdown-item" onClick={() => handleDeleteMessage(message.message_id, message.conv_id)}>Delete message</Link></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>)
                                                                        : null
                                                                }
                                                                <span className="msg_time mx-2">

                                                                    {message.is_star === 1 ? (
                                                                        <FontAwesomeIcon icon={faStar} style={{ marginLeft: '5px' }} />) : (null)
                                                                    }
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            message.message_status === "0" ? faClock :
                                                                                message.message_status === "3" ? faCheckDouble :
                                                                                    message.message_status === "2" ? faCheckDouble :
                                                                                        message.Authorization ? faClock : faCheck
                                                                        }
                                                                        style={{
                                                                            color:
                                                                                message.message_status === "3" ? '#0dcaf0' :
                                                                                    message.message_status === "2" ? 'white' :
                                                                                        message.Authorization ? 'white' : 'inherit',
                                                                            marginLeft: !message.Authorization && '5px'
                                                                        }}
                                                                    />
                                                                </span>
                                                                <span className="msg_time_send">{message.message_id ? moment.utc(message.created_at, "YYYY-MM-DD HH:mm:ss").local().format("h:mm A") : moment().utc(message.created_at, "YYYY-MM-DD HH:mm:ss").format("h:mm A")}</span>
                                                            </div>
                                                        </div>
                                                        : <div key={index} className="d-flex justify-content-start">
                                                            <div className="message message-recive">
                                                                {
                                                                    message.content_type == 1 ? (
                                                                        <>
                                                                            {currChat.conv_type == 2 ?
                                                                                <div className="group-chat-messages">
                                                                                    <div className="sender-name">
                                                                                        <span>
                                                                                            {currChat.conv_type == 2 && message ? (message.username || message.number) : ''}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="msg_cotainer">
                                                                                        {processString(message.content)}
                                                                                        <div className="dropdown message-dropdown ">
                                                                                            <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                                                                                aria-expanded="false"><svg width="15" height="15" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <g clipPath="url(#clip0_1343_2)">
                                                                                                        <path d="M6.46824 9.33353L11.8083 3.99338C11.9319 3.86987 12 3.70499 12 3.52919C12 3.35339 11.9319 3.18851 11.8083 3.065L11.4151 2.67173C11.1589 2.41583 10.7425 2.41583 10.4867 2.67173L6.00249 7.15599L1.51326 2.66676C1.38965 2.54325 1.22487 2.47505 1.04916 2.47505C0.873261 2.47505 0.708483 2.54325 0.584776 2.66676L0.191707 3.06002C0.068098 3.18363 9.15499e-07 3.34841 9.07814e-07 3.52421C9.00129e-07 3.70002 0.068098 3.86489 0.191707 3.98841L5.53664 9.33353C5.66064 9.45734 5.8262 9.52534 6.0022 9.52495C6.17888 9.52534 6.34434 9.45734 6.46824 9.33353Z" fill="#1F2128"></path>
                                                                                                    </g>
                                                                                                    <defs>
                                                                                                        <clipPath id="clip0_1343_2">
                                                                                                            <rect width="12" height="12" fill="white" transform="translate(12) rotate(90)"></rect>
                                                                                                        </clipPath>
                                                                                                    </defs>
                                                                                                </svg></span>
                                                                                            <ul className="dropdown-menu  dropdown-menu-dark">
                                                                                                <li><Link className="dropdown-item" onClick={() => handleCopyMessage(message.content)}>Copy message</Link></li>
                                                                                                {message.is_star === 1 ? (
                                                                                                    <li>
                                                                                                        <Link className="dropdown-item" onClick={() => handleStarMessage(message.message_id, message.conv_id, 0)}>
                                                                                                            Unstar message
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                ) : (
                                                                                                    <li>
                                                                                                        <Link className="dropdown-item" onClick={() => handleStarMessage(message.message_id, message.conv_id, 1)}>
                                                                                                            Star message
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                )}
                                                                                                <li><Link className="dropdown-item" onClick={() => handleDeleteMessage(message.message_id, message.conv_id)}>Delete message</Link></li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <>
                                                                                    <div className="sender-name">
                                                                                        <span>
                                                                                            {currChat.conv_type == 2 && message ? (message.username || '-') : ''}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="msg_cotainer">
                                                                                        {processString(message.content)}
                                                                                        <div className="dropdown message-dropdown ">
                                                                                            <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                                                                                aria-expanded="false"><svg width="15" height="15" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <g clipPath="url(#clip0_1343_2)">
                                                                                                        <path d="M6.46824 9.33353L11.8083 3.99338C11.9319 3.86987 12 3.70499 12 3.52919C12 3.35339 11.9319 3.18851 11.8083 3.065L11.4151 2.67173C11.1589 2.41583 10.7425 2.41583 10.4867 2.67173L6.00249 7.15599L1.51326 2.66676C1.38965 2.54325 1.22487 2.47505 1.04916 2.47505C0.873261 2.47505 0.708483 2.54325 0.584776 2.66676L0.191707 3.06002C0.068098 3.18363 9.15499e-07 3.34841 9.07814e-07 3.52421C9.00129e-07 3.70002 0.068098 3.86489 0.191707 3.98841L5.53664 9.33353C5.66064 9.45734 5.8262 9.52534 6.0022 9.52495C6.17888 9.52534 6.34434 9.45734 6.46824 9.33353Z" fill="#1F2128"></path>
                                                                                                    </g>
                                                                                                    <defs>
                                                                                                        <clipPath id="clip0_1343_2">
                                                                                                            <rect width="12" height="12" fill="white" transform="translate(12) rotate(90)"></rect>
                                                                                                        </clipPath>
                                                                                                    </defs>
                                                                                                </svg></span>
                                                                                            <ul className="dropdown-menu  dropdown-menu-dark">
                                                                                                <li><Link className="dropdown-item" onClick={() => handleCopyMessage(message.content)}>Copy message</Link></li>
                                                                                                {message.is_star === 1 ? (
                                                                                                    <li>
                                                                                                        <Link className="dropdown-item" onClick={() => handleStarMessage(message.message_id, message.conv_id, 0)}>
                                                                                                            Unstar message
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                ) : (
                                                                                                    <li>
                                                                                                        <Link className="dropdown-item" onClick={() => handleStarMessage(message.message_id, message.conv_id, 1)}>
                                                                                                            Star message
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                )}
                                                                                                <li><Link className="dropdown-item" onClick={() => handleDeleteMessage(message.message_id, message.conv_id)}>Delete message</Link></li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </>
                                                                    ) : message.content_type == 2 ? (
                                                                        <div className="group-chat-messages">
                                                                            {currChat.conv_type == 2 &&
                                                                                <div className="sender-name">
                                                                                    <span>
                                                                                        {currChat.conv_type == 2 && message ? (message.username || message.number) : ''}
                                                                                    </span>
                                                                                </div>
                                                                            }
                                                                            <div className="msg_container img_cont_msg position-relative" style={{ borderRadius: '15px 15px 15px 15px' }}>


                                                                                <img
                                                                                    height="145"
                                                                                    width="245"
                                                                                    src={message.content}
                                                                                    alt=""
                                                                                    onClick={() => handleMediaClick1(message.content, 'image')}
                                                                                />
                                                                                <div className="dropdown message-dropdown ">
                                                                                    <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                                                                        aria-expanded="false"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <g clipPath="url(#clip0_1343_2)">
                                                                                                <path d="M6.46824 9.33353L11.8083 3.99338C11.9319 3.86987 12 3.70499 12 3.52919C12 3.35339 11.9319 3.18851 11.8083 3.065L11.4151 2.67173C11.1589 2.41583 10.7425 2.41583 10.4867 2.67173L6.00249 7.15599L1.51326 2.66676C1.38965 2.54325 1.22487 2.47505 1.04916 2.47505C0.873261 2.47505 0.708483 2.54325 0.584776 2.66676L0.191707 3.06002C0.068098 3.18363 9.15499e-07 3.34841 9.07814e-07 3.52421C9.00129e-07 3.70002 0.068098 3.86489 0.191707 3.98841L5.53664 9.33353C5.66064 9.45734 5.8262 9.52534 6.0022 9.52495C6.17888 9.52534 6.34434 9.45734 6.46824 9.33353Z" fill="#1F2128"></path>
                                                                                            </g>
                                                                                            <defs>
                                                                                                <clipPath id="clip0_1343_2">
                                                                                                    <rect width="12" height="12" fill="white" transform="translate(12) rotate(90)"></rect>
                                                                                                </clipPath>
                                                                                            </defs>
                                                                                        </svg></span>
                                                                                    <ul className="dropdown-menu dropdown-menu-dark">
                                                                                        <li><Link className="dropdown-item" onClick={() => handleMediaClick1(message.content, 'image')}>View message</Link></li>
                                                                                        {message.is_star === 1 ? (
                                                                                            <li>
                                                                                                <Link className="dropdown-item" onClick={() => handleStarMessage(message.message_id, message.conv_id, 0)}>
                                                                                                    Unstar message
                                                                                                </Link>
                                                                                            </li>
                                                                                        ) : (
                                                                                            <li>
                                                                                                <Link className="dropdown-item" onClick={() => handleStarMessage(message.message_id, message.conv_id, 1)}>
                                                                                                    Star message
                                                                                                </Link>
                                                                                            </li>
                                                                                        )}
                                                                                        <li><Link className="dropdown-item" onClick={() => handleDeleteMessage(message.message_id, message.conv_id)}>Delete message</Link></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : message.content_type == 3 ? (
                                                                        <>
                                                                            <div className="group-chat-messages">
                                                                                {currChat.conv_type == 2 &&
                                                                                    <div className="sender-name">
                                                                                        <span>
                                                                                            {currChat.conv_type == 2 && message ? (message.username || message.number) : ''}
                                                                                        </span>
                                                                                    </div>
                                                                                }
                                                                                <div className="msg_cotainer">
                                                                                    <div className="dropdown message-dropdown ">
                                                                                        <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                                                                            aria-expanded="false"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <g clipPath="url(#clip0_1343_2)">
                                                                                                    <path d="M6.46824 9.33353L11.8083 3.99338C11.9319 3.86987 12 3.70499 12 3.52919C12 3.35339 11.9319 3.18851 11.8083 3.065L11.4151 2.67173C11.1589 2.41583 10.7425 2.41583 10.4867 2.67173L6.00249 7.15599L1.51326 2.66676C1.38965 2.54325 1.22487 2.47505 1.04916 2.47505C0.873261 2.47505 0.708483 2.54325 0.584776 2.66676L0.191707 3.06002C0.068098 3.18363 9.15499e-07 3.34841 9.07814e-07 3.52421C9.00129e-07 3.70002 0.068098 3.86489 0.191707 3.98841L5.53664 9.33353C5.66064 9.45734 5.8262 9.52534 6.0022 9.52495C6.17888 9.52534 6.34434 9.45734 6.46824 9.33353Z" fill="#1F2128"></path>
                                                                                                </g>
                                                                                                <defs>
                                                                                                    <clipPath id="clip0_1343_2">
                                                                                                        <rect width="12" height="12" fill="white" transform="translate(12) rotate(90)"></rect>
                                                                                                    </clipPath>
                                                                                                </defs>
                                                                                            </svg></span>
                                                                                        <ul className="dropdown-menu dropdown-menu-dark">
                                                                                            {message.is_star === 1 ? (
                                                                                                <li>
                                                                                                    <Link className="dropdown-item" onClick={() => handleStarMessage(message.message_id, message.conv_id, 0)}>
                                                                                                        Unstar message
                                                                                                    </Link>
                                                                                                </li>
                                                                                            ) : (
                                                                                                <li>
                                                                                                    <Link className="dropdown-item" onClick={() => handleStarMessage(message.message_id, message.conv_id, 1)}>
                                                                                                        Star message
                                                                                                    </Link>
                                                                                                </li>
                                                                                            )}
                                                                                            <li><Link className="dropdown-item" onClick={() => handleDeleteMessage(message.message_id, message.conv_id)}>Delete message</Link></li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div className="voice-message-box">
                                                                                        <AudioMessage
                                                                                            key={message.message_id}
                                                                                            message={message}
                                                                                            isPlaying={playingMessageId === message.message_id}
                                                                                            progress={progresses[message.message_id] || 0}
                                                                                            currentTime={currentTimes[message.message_id] || 0}
                                                                                            onPlayPause={handlePlayPause}
                                                                                            onSeek={handleSeek}
                                                                                            onTimeUpdate={handleTimeUpdate}
                                                                                            ref={(el) => { audioRefs.current[message.message_id] = el && el.getAudio(); }}
                                                                                        />
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : message.content_type == 4 ? (
                                                                        <div className=" msg_cotainer_send video_cont_send position-relative " style={{ borderRadius: '15px 15px 15px 15px', background: 'var(--white)' }} >

                                                                            <div className="video-container" >
                                                                                <div className="lightboxContainer">
                                                                                    <img src={message.thumbnail} alt="thumbnail" style={{ borderRadius: '15px 15px 15px 15px' }} />
                                                                                    <img src={play_btn} alt="" className='video-play-btn' onClick={() => handleMediaClick1(message.content, 'video')} />

                                                                                </div>
                                                                            </div>
                                                                            <div className="dropdown message-dropdown ">
                                                                                <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                                                                    aria-expanded="false"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g clipPath="url(#clip0_1343_2)">
                                                                                            <path d="M6.46824 9.33353L11.8083 3.99338C11.9319 3.86987 12 3.70499 12 3.52919C12 3.35339 11.9319 3.18851 11.8083 3.065L11.4151 2.67173C11.1589 2.41583 10.7425 2.41583 10.4867 2.67173L6.00249 7.15599L1.51326 2.66676C1.38965 2.54325 1.22487 2.47505 1.04916 2.47505C0.873261 2.47505 0.708483 2.54325 0.584776 2.66676L0.191707 3.06002C0.068098 3.18363 9.15499e-07 3.34841 9.07814e-07 3.52421C9.00129e-07 3.70002 0.068098 3.86489 0.191707 3.98841L5.53664 9.33353C5.66064 9.45734 5.8262 9.52534 6.0022 9.52495C6.17888 9.52534 6.34434 9.45734 6.46824 9.33353Z" fill="#1F2128"></path>
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_1343_2">
                                                                                                <rect width="12" height="12" fill="white" transform="translate(12) rotate(90)"></rect>
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg></span>
                                                                                <ul className="dropdown-menu dropdown-menu-dark">

                                                                                    <li><Link className="dropdown-item" onClick={() => handleMediaClick1(message.content, 'video')}>View message</Link></li>
                                                                                    {message.is_star === 1 ? (
                                                                                        <li>
                                                                                            <Link className="dropdown-item" onClick={() => handleStarMessage(message.message_id, message.conv_id, 0)}>
                                                                                                Unstar message
                                                                                            </Link>
                                                                                        </li>
                                                                                    ) : (
                                                                                        <li>
                                                                                            <Link className="dropdown-item" onClick={() => handleStarMessage(message.message_id, message.conv_id, 1)}>
                                                                                                Star message
                                                                                            </Link>
                                                                                        </li>
                                                                                    )}
                                                                                    <li><Link className="dropdown-item" onClick={() => handleDeleteMessage(message.message_id, message.conv_id)}>Delete message</Link></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>)
                                                                        : null
                                                                }
                                                                <span className="msg_time">{utcToLocalConvertorTime(message.created_at)}
                                                                    {message.is_star === 1 ? (
                                                                        <FontAwesomeIcon icon={faStar} style={{ marginLeft: '5px' }} />) : (null)
                                                                    }
                                                                    {message.isOffline &&
                                                                        <FontAwesomeIcon icon={faClock} style={{ marginLeft: '5px' }} />
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                ) : <div className="empty-message-box">
                                                    <img src={empty_msg_img} alt="" />
                                                    <p className="empty-message">No Messages Found</p>
                                                </div>}
                                        </InfiniteScroll>
                                    </div>
                                    {currChat.conv_type === "1" ? (
                                        <>
                                            {!recordingFooter ? (
                                                <div className="box-footer">
                                                    <div className="footer-left">
                                                        {pickerToggler && (
                                                            <div ref={pickerRef} style={{ position: 'absolute', bottom: '80px' }}>
                                                                <Picker data={data} onEmojiSelect={handleEmojiSelect} />
                                                            </div>
                                                        )}
                                                        <img
                                                            ref={emojiRef}
                                                            onClick={() => setPickerToggler(!pickerToggler)}
                                                            src={emoji}
                                                            alt="Emoji"
                                                            className="footer-img"
                                                        />
                                                    </div>
                                                    <img
                                                        src={plus_btn}
                                                        alt="AddMedia"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={handleMediaClick}
                                                    />
                                                    <form onSubmit={handleSendMessage}>
                                                        <div className="input-group pb-0">
                                                            <div className="input-field">
                                                                {uploadProgress > 0 && (
                                                                    <Progress percent={uploadProgress} status="active" />
                                                                )}
                                                                <input
                                                                    className="test"
                                                                    name="message"
                                                                    placeholder="Type Message..."
                                                                    value={message}
                                                                    onChange={(e) => setMessage(e.target.value)}
                                                                />
                                                                <div className="chat-control">
                                                                    <img
                                                                        onClick={handleSendMessage}
                                                                        src={send_btn}
                                                                        alt=""
                                                                        className="footer-img"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div className="footer-right">
                                                        {!recording && (
                                                            <img
                                                                src={voice_btn}
                                                                alt=""
                                                                className="footer-img"
                                                                onClick={startRecording}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            ) : audioCurrChat === currentAudioRecorder ? (
                                                <div className="box-footer voice-message">
                                                    <div className="delete-box" onClick={stopRecording}>
                                                        <i><FontAwesomeIcon icon={faTrashCan} /></i>
                                                    </div>
                                                    <div className="voice-message-timer">
                                                        {formatcounterTime(time.minutes)}:{formatcounterTime(time.seconds)}
                                                    </div>
                                                    <div className="chat-control">
                                                        <a className="send-btn">
                                                            <span>
                                                                <img src={send_btn} alt="send-icon" onClick={handleAudioUpload} />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            ) : <div className="box-footer">
                                                <div className="footer-left">
                                                    {pickerToggler && (
                                                        <div ref={pickerRef} style={{ position: 'absolute', bottom: '80px' }}>
                                                            <Picker data={data} onEmojiSelect={handleEmojiSelect} />
                                                        </div>
                                                    )}
                                                    <img
                                                        ref={emojiRef}
                                                        onClick={() => setPickerToggler(!pickerToggler)}
                                                        src={emoji}
                                                        alt="Emoji"
                                                        className="footer-img"
                                                    />
                                                </div>
                                                <img
                                                    src={plus_btn}
                                                    alt="AddMedia"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={handleMediaClick}
                                                />
                                                <form onSubmit={handleSendMessage}>
                                                    <div className="input-group pb-0">
                                                        <div className="input-field">
                                                            {uploadProgress > 0 && (
                                                                <Progress percent={uploadProgress} status="active" />
                                                            )}
                                                            <input
                                                                className="test"
                                                                name="message"
                                                                placeholder="Type Message..."
                                                                value={message}
                                                                onChange={(e) => setMessage(e.target.value)}
                                                            />
                                                            <div className="chat-control">
                                                                <img
                                                                    onClick={handleSendMessage}
                                                                    src={send_btn}
                                                                    alt=""
                                                                    className="footer-img"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="footer-right">
                                                    {!recording && (
                                                        <img
                                                            src={voice_btn}
                                                            alt=""
                                                            className="footer-img"
                                                            onClick={startRecording}
                                                        />
                                                    )}
                                                </div>
                                            </div>}
                                        </>
                                    ) : currChat.conv_type === "2" && currChat.is_removed === "0" ? (
                                        <>
                                            {!recordingFooter ? (
                                                <div className="box-footer">
                                                    <div className="footer-left">
                                                        {pickerToggler && (
                                                            <div ref={pickerRef} style={{ position: 'absolute', bottom: '80px' }}>
                                                                <Picker data={data} onEmojiSelect={handleEmojiSelect} />
                                                            </div>
                                                        )}
                                                        <img
                                                            ref={emojiRef}
                                                            onClick={() => setPickerToggler(!pickerToggler)}
                                                            src={emoji}
                                                            alt="Emoji"
                                                            className="footer-img"
                                                        />
                                                    </div>
                                                    <img
                                                        src={plus_btn}
                                                        alt="AddMedia"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={handleMediaClick}
                                                    />
                                                    <form onSubmit={handleSendMessage}>
                                                        <div className="input-group pb-0">
                                                            <div className="input-field">
                                                                {uploadProgress > 0 && (
                                                                    <Progress percent={uploadProgress} status="active" />
                                                                )}
                                                                <input
                                                                    className="test"
                                                                    name="message"
                                                                    placeholder="Type Message..."
                                                                    value={message}
                                                                    onChange={(e) => setMessage(e.target.value)}
                                                                />
                                                                <div className="chat-control">
                                                                    <img
                                                                        onClick={handleSendMessage}
                                                                        src={send_btn}
                                                                        alt=""
                                                                        className="footer-img"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div className="footer-right">
                                                        {!recording && (
                                                            <img
                                                                src={voice_btn}
                                                                alt=""
                                                                className="footer-img"
                                                                onClick={startRecording}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            ) : audioCurrChat === currentAudioRecorder ? (
                                                <div className="box-footer voice-message">
                                                    <div className="delete-box" onClick={stopRecording}>
                                                        <i><FontAwesomeIcon icon={faTrashCan} /></i>
                                                    </div>
                                                    <div className="voice-message-timer">
                                                        {formatcounterTime(time.minutes)}:{formatcounterTime(time.seconds)}
                                                    </div>
                                                    <div className="chat-control">
                                                        <a className="send-btn">
                                                            <span>
                                                                <img src={send_btn} alt="send-icon" onClick={handleAudioUpload} />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            ) : <div className="box-footer">
                                                <div className="footer-left">
                                                    {pickerToggler && (
                                                        <div ref={pickerRef} style={{ position: 'absolute', bottom: '80px' }}>
                                                            <Picker data={data} onEmojiSelect={handleEmojiSelect} />
                                                        </div>
                                                    )}
                                                    <img
                                                        ref={emojiRef}
                                                        onClick={() => setPickerToggler(!pickerToggler)}
                                                        src={emoji}
                                                        alt="Emoji"
                                                        className="footer-img"
                                                    />
                                                </div>
                                                <img
                                                    src={plus_btn}
                                                    alt="AddMedia"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={handleMediaClick}
                                                />
                                                <form onSubmit={handleSendMessage}>
                                                    <div className="input-group pb-0">
                                                        <div className="input-field">
                                                            {uploadProgress > 0 && (
                                                                <Progress percent={uploadProgress} status="active" />
                                                            )}
                                                            <input
                                                                className="test"
                                                                name="message"
                                                                placeholder="Type Message..."
                                                                value={message}
                                                                onChange={(e) => setMessage(e.target.value)}
                                                            />
                                                            <div className="chat-control">
                                                                <img
                                                                    onClick={handleSendMessage}
                                                                    src={send_btn}
                                                                    alt=""
                                                                    className="footer-img"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="footer-right">
                                                    {!recording && (
                                                        <img
                                                            src={voice_btn}
                                                            alt=""
                                                            className="footer-img"
                                                            onClick={startRecording}
                                                        />
                                                    )}
                                                </div>
                                            </div>}
                                        </>
                                    ) : currChat.conv_type === "2" && currChat.is_removed === "1" ? (
                                        <div>
                                            <p>You can't send a message to this group because you're no longer a member.</p>
                                        </div>
                                    ) : (
                                        <>
                                            {!recordingFooter ? (
                                                <div className="box-footer">
                                                    <div className="footer-left">
                                                        {pickerToggler && (
                                                            <div ref={pickerRef} style={{ position: 'absolute', bottom: '80px' }}>
                                                                <Picker data={data} onEmojiSelect={handleEmojiSelect} />
                                                            </div>
                                                        )}
                                                        <img
                                                            ref={emojiRef}
                                                            onClick={() => setPickerToggler(!pickerToggler)}
                                                            src={emoji}
                                                            alt="Emoji"
                                                            className="footer-img"
                                                        />
                                                    </div>
                                                    <img
                                                        src={plus_btn}
                                                        alt="AddMedia"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={handleMediaClick}
                                                    />
                                                    <form onSubmit={handleSendMessage}>
                                                        <div className="input-group pb-0">
                                                            <div className="input-field">
                                                                <input
                                                                    className="test"
                                                                    name="message"
                                                                    placeholder="Type Message..."
                                                                    value={message}
                                                                    onChange={(e) => setMessage(e.target.value)}
                                                                />
                                                                <div className="chat-control">
                                                                    <img
                                                                        onClick={handleSendMessage}
                                                                        src={send_btn}
                                                                        alt=""
                                                                        className="footer-img"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div className="footer-right">
                                                        {!recording && (
                                                            <img
                                                                src={voice_btn}
                                                                alt=""
                                                                className="footer-img"
                                                                onClick={startRecording}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            ) : audioCurrChat === currentAudioRecorder ? (
                                                <div className="box-footer voice-message">
                                                    <div className="delete-box" onClick={stopRecording}>
                                                        <i><FontAwesomeIcon icon={faTrashCan} /></i>
                                                    </div>
                                                    <div className="voice-message-timer">
                                                        {formatcounterTime(time.minutes)}:{formatcounterTime(time.seconds)}
                                                    </div>
                                                    <div className="chat-control">
                                                        <a className="send-btn">
                                                            <span>
                                                                <img src={send_btn} alt="send-icon" onClick={handleAudioUpload} />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            ) : <div className="box-footer">
                                                <div className="footer-left">
                                                    {pickerToggler && (
                                                        <div ref={pickerRef} style={{ position: 'absolute', bottom: '80px' }}>
                                                            <Picker data={data} onEmojiSelect={handleEmojiSelect} />
                                                        </div>
                                                    )}
                                                    <img
                                                        ref={emojiRef}
                                                        onClick={() => setPickerToggler(!pickerToggler)}
                                                        src={emoji}
                                                        alt="Emoji"
                                                        className="footer-img"
                                                    />
                                                </div>
                                                <img
                                                    src={plus_btn}
                                                    alt="AddMedia"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={handleMediaClick}
                                                />
                                                <form onSubmit={handleSendMessage}>
                                                    <div className="input-group pb-0">
                                                        <div className="input-field">
                                                            {uploadProgress > 0 && (
                                                                <Progress percent={uploadProgress} status="active" />
                                                            )}
                                                            <input
                                                                className="test"
                                                                name="message"
                                                                placeholder="Type Message..."
                                                                value={message}
                                                                onChange={(e) => setMessage(e.target.value)}
                                                            />
                                                            <div className="chat-control">
                                                                <img
                                                                    onClick={handleSendMessage}
                                                                    src={send_btn}
                                                                    alt=""
                                                                    className="footer-img"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="footer-right">
                                                    {!recording && (
                                                        <img
                                                            src={voice_btn}
                                                            alt=""
                                                            className="footer-img"
                                                            onClick={startRecording}
                                                        />
                                                    )}
                                                </div>
                                            </div>}
                                        </>
                                    )}


                                </div>
                            </div>
                            {/* {media modal} */}
                            <Modal
                                dialogClassName="preview-modal  modal-dialog-centered modal-lg"
                                show={modalIsOpen}
                                onHide={closeModal}
                            >
                                <div onClick={closeModal} className="modal-close-btn">×</div>
                                {mediaType === 'image' && (
                                    <img src={mediaSrc} height="100%"
                                        width="100%" alt="Full size preview" />
                                )}
                                {mediaType === 'video' && (
                                    <video controls height={500} width={1500} >
                                        <source src={mediaSrc} type="video/mp4" />

                                    </video>
                                )}

                            </Modal>
                            {/* Report user modal start  */}
                            <div
                                className="modal "
                                id="reportUser"
                                role="dialog"
                                data-bs-backdrop="static"
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    {/* Modal content*/}
                                    <div className="modal-content payment-card">
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                        <div className="modal-header">
                                            <h5 className="text-center">Report User</h5>
                                        </div>
                                        <div className="modal-body">
                                            <form>
                                                <div className="common-form">
                                                    <div className="input-group flex-column">
                                                        <textarea
                                                            name=""
                                                            id=""
                                                            rows={5}
                                                            placeholder="Enter Description"
                                                            defaultValue={""}
                                                        />
                                                    </div>
                                                </div>
                                                <button
                                                    type="submit"
                                                    className="btn w-100  orange-color shadow-none"
                                                >
                                                    Report user
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Report user modal end */}
                        </div>

                    </div >
                    : null
                }

                {/* ContactInfo */}
                <div ref={ContactInfoDrawerRef} className={`drawer-right ${isContactInfoOpen ? 'open' : ''}`}>
                    <ContactInfo currChat={currChat} reportConversation={reportConversation} blockToggler={blockToggler} />
                </div>

                {/* GroupInfo */}
                <div ref={GroupInfoInfoDrawerRef} className={`drawer-right ${isGroupInfoOpen ? 'open' : ''}`}>
                    <GroupInfo currChat={currChat} reportConversation={reportConversation} exitGroup={exitGroup} drawer={isGroupInfoOpen} />
                </div>

            </div >
        </div >

    )
}

export default Chatwindow