import React, { useEffect, useState, useContext, useRef, createRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCommentsDollar, faEllipsisVertical, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/Api'
import user from '../../assets/images/user.png'
import nextIcon from '../../assets/images/next-icon.png'
import close from '../../assets/images/modal-close-btn.png'
import { useDispatch, useSelector } from 'react-redux';
import { setNewGroupRightDrawer, setNewGroupDrawer, setGroupInfoDrawer } from '../../redux/actions/drawerActions';
import { setGroupMembers } from '../../redux/actions/convListActions';
import { sortAlphabetic, useToast } from '../../utils/helpers/CommFun';
import SocketContext from "../../context/SocketContext";
import empty_msg_img from '../../assets/images/no_message.png'
function AddGroupMemberRight({ drawer }) {
    const dispatch = useDispatch();
    const socket = useContext(SocketContext);

    const { contactList, groupMembers, conversationList } = useSelector(state => state.conversation);
    const { isAddGroupOpen, isNewGroupOpen } = useSelector(state => state.drawer);
    const { currChat } = useSelector(state => state.conversation);
    const { newGroupList } = useSelector(state => state.conversation);

    //get local data
    const usertoken = localStorage.getItem('token')
    const userData = JSON.parse(localStorage.getItem('userData'))

    const [UserList, setUserList] = useState([])
    const [UserList2, setUserList2] = useState([])
    const [SelectedUserList, setSelectedUserList] = useState([])
    const toast = useToast();
    const [grpdata, setGrpData] = useState([]);

    //drawers
    const { isNewGroupRightOpen } = useSelector(state => state.drawer);

    useEffect(() => {
        getContactList()
    }, [currChat])
    
    const getContactList = async () => {
        const contactConvList = contactList.filter(member => member.conv_type == 1 && member.conv_id != null);
        const userIdsInData2 = new Set(groupMembers.map(item => item.user_id));
        const groupMembersfilter = contactConvList.filter(item => !userIdsInData2.has(item.user_id));

        setUserList(groupMembersfilter);
        setUserList2(groupMembersfilter);
    };

    // handle select member   
    const handleSelect = (data) => {
        if (data && data.user_id) {
            const updatedUserList = UserList.filter(user => user.user_id !== data.user_id);
            const selectedUser = UserList.find(user => user.user_id === data.user_id);
            if (selectedUser) {
                setUserList(updatedUserList.sort(sortAlphabetic));
                setSelectedUserList(prevData => [...prevData, selectedUser].sort(sortAlphabetic));
            }
        }
    };

    //selected member remove function
    const handleUnSelect = (data) => {
        if (data && data.user_id) {
            const updatedSelectedUserList = SelectedUserList.filter(user => user.user_id !== data.user_id);
            const cancelledUser = SelectedUserList.find(user => user.user_id === data.user_id);
            if (cancelledUser) {
                const updatedUserList = [...UserList, cancelledUser].sort(sortAlphabetic);
                setUserList(updatedUserList);
                setSelectedUserList(updatedSelectedUserList.sort(sortAlphabetic));
            }
        }
    };

    // add member function
    const addGroupMember = async (participantIds = []) => {
        try {
            const body = {
                conv_id: currChat.conv_id,
                participant_ids: newGroupList.toString(),
            };
            if (participantIds.length > 0) {
                body.participant_ids = participantIds.join(',');
            }
            const response = await api.post(`/add_member`, body, usertoken);
            if (response.code === 200) {
                toast.fire({
                    icon: "success",
                    title: response.message,
                });
                getContactList()
                dispatch(setNewGroupRightDrawer(false));
                setSelectedUserList([]);
            } else {
                toast.fire({
                    icon: "error",
                    title: response.message,
                });
            }
        } catch (error) {
            console.error("Error adding group member:", error);
        }
    };

    //socket event 
    const onaddMember = (data) => {
        const updatedMembers = [
            ...groupMembers,
            ...data
        ];
        dispatch(setGroupMembers(updatedMembers));
    }

    useEffect(() => {
        if (socket) {
            socket.on("add_member", onaddMember);
            return () => {
                socket.off("add_member", onaddMember);
            };
        }
    }, [socket, addGroupMember]);


    const setParticipants = () => {
        return SelectedUserList.map(user => user.user_id);
    };

    useEffect(() => {
        setGrpData(groupMembers);
    }, [setGroupInfoDrawer]);

    const handleSubmit = () => {
        if (SelectedUserList.length > 0) {
            const participantIds = setParticipants();
            addGroupMember(participantIds);
        }
    };

    useEffect(() => {
        getContactList();
        setSelectedUserList([]);
    }, [contactList, groupMembers]);

    useEffect(() => {
        if (!isAddGroupOpen && !isNewGroupOpen) {
            getContactList();
            setSelectedUserList([]);
        }
    }, [isNewGroupOpen, isAddGroupOpen])

    //search functionality
    const [search, setSearch] = useState("")
    const filterList = (list, query) => {
        const lowerCaseQuery = query.toLowerCase().trim();
        if (!lowerCaseQuery || lowerCaseQuery === null) {
            return UserList2;
        }
        return list.filter(item => {
            const contactName = item.contact_name ? item.contact_name.toLowerCase() : '';
            const number = item.number ? item.number.toString() : '';
            const match = contactName.includes(lowerCaseQuery) || number.includes(lowerCaseQuery);
            return match;
        });
    };

    //search function
    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();;
        setSearch(query);
        const filteredUsers = filterList(UserList, query);
        setUserList(filteredUsers);
    };
    const closing = () => {
        dispatch(setNewGroupRightDrawer(false));
        setSelectedUserList([]);
    }
    useEffect(() => { setSelectedUserList([]) }, [isNewGroupRightOpen])

    const messagesEndRef = createRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView();
    };
    useEffect(() => { scrollToBottom() }, [drawer])
    return (
        <>  <div className="left-user-box">
            <div className="contact-profile big">
                <div className="call-setting-tab ms-0">
                    <div className="ms-0 btn mx-3" onClick={closing} >
                        <i><FontAwesomeIcon icon={faArrowLeft} /></i>
                    </div>
                    <h5>Add group members</h5>
                </div>
            </div>
            <div className="chat-box chat-box-left">
                <div className="selected-user-tab">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="selected-box" id="scroll">
                                {SelectedUserList.map((data, index) => (
                                    <div className="user-item" key={index}>
                                        <div className="nav-link">
                                            <div className="user-chat-box">
                                                <div className="d-flex align-items-center">
                                                    <div className="img-box">
                                                        <img
                                                            src={data.profile_pic && data.profile_pic.trim() !== "" ? data.profile_pic : user} />
                                                    </div>
                                                    <div className="user-info text-start">
                                                        <h5>{data.username != null ? data.username : data.contact_name}</h5>
                                                    </div>
                                                    <img onClick={() => handleUnSelect(data)} src={close} alt="" className="close" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="new-group-box d-block">
                            <div className="col-12">
                                <input type="text" className="form-control" id="" placeholder="Search name or number" alt="" value={search}
                                    onChange={handleSearchChange} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-content" id="nav-tabContent">
                    {/* create new group chat  */}
                    <div className="tab-pane fade active show" id="nav-User" role="tabpanel" aria-labelledby="nav-User-tab">

                        {UserList && UserList.length > 0 ?
                            <nav className="user-chat-nav newgroup-box" id="scrollbar">
                                <div ref={messagesEndRef}></div>
                                {UserList.map((data, index) => (
                                    <div className="nav-link" key={index} onClick={() => handleSelect(data)} >
                                        <div className="user-chat-box">
                                            <a className="d-flex align-items-center">
                                                <div className="img-box">
                                                    {data.profile_pic == null ?
                                                        <img
                                                            src={user}
                                                            alt=""
                                                        />
                                                        : <img
                                                            src={data.profile_pic}
                                                            alt=""
                                                        />
                                                    }
                                                </div>
                                                <div className="user-info text-start">
                                                    <h5>{data.username != null ? data.username : data.contact_name}</h5>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                ))}

                            </nav>
                            : <div className="empty-message-box">
                                <img src={empty_msg_img} alt="" />
                                <p className="empty-message">No Messages Found</p>
                            </div>}
                    </div>
                </div>
                <div className="next-btn-box">
                    <div className="img-box" onClick={() => handleSubmit()}>
                        {/* <i><FontAwesomeIcon icon={faArrowRight} /></i> */}
                        <div className="img-box">
                            <img src={nextIcon} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div >
        </>

    )
}

export default AddGroupMemberRight;