import { format } from "date-fns-tz";
import moment from "moment";
import Swal from "sweetalert2";

//empty check 
export function isEmpty(value) {
  return value === null || value === undefined || value === false || value === 0 || (typeof value === "string" && !value.trim());
}
export function validateStr(str) {
  return (str === null || str === undefined || str == "" || (typeof str === "string" && !str.trim())) ? false : true;
}
export function validateNum(num, len) {
  return (num === null || num === undefined || num == "" || parseInt(num).toString().length != len) ? false : true;
}

//email valid check
export function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

//phone valid function
export const validatePhoneNumber = (number) => {
  const phoneRegex = /^[2-9]{1}[0-9]{9}$/;
  return phoneRegex.test(number);
};

//Swal sweet alert 
export function useToast() {
  return Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
}
export function sortAlphabetic(a, b) {
  if (a.contact_name == null || b.contact_name == null)
    return 0;
  let usernameA = a.contact_name.toLowerCase();
  let usernameB = b.contact_name.toLowerCase();
  if (usernameA < usernameB)
    return -1;
  if (usernameA > usernameB)
    return 1;
  return 0;
}
export function sortByCreatedAt(a, b) {
  const dateA = new Date(a.created_at);
  const dateB = new Date(b.created_at);
  if (dateA < dateB) {
    return -1;
  }
  if (dateA > dateB) {
    return 1;
  }
}
export function convertDate(createdTime) {
  let date = new Date(createdTime);
  const formattedTime = date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true
  });
  return formattedTime;
}
export function listTimeFormat(createdTime) {
  let date = new Date(createdTime);
  const formattedTime = date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true
  });
  return formattedTime;
}

export function utcToLocalConvertor(date) {
  return new Date(Date.parse(date + ' UTC')).toLocaleString();
}

export function utcToLocalConvertorTime(date) {
  const utcDate = new Date(Date.parse(date + ' UTC'));
  const timeString = utcDate.toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
  return timeString;
}

export function formatDateBasedOnRecency(utcDateStr) {
  if (utcDateStr == null || utcDateStr == undefined) {
    return '';
  }
  const localDate = new Date(Date.parse(utcDateStr + ' UTC')).toLocaleString();
  const date = new Date(utcDateStr);
  const now = new Date();
  const oneDay = 24 * 60 * 60 * 1000;

  const diffDays = Math.floor((now - date) / oneDay);


  let formattedDate;
  if (diffDays === 0) {
    formattedDate = utcToLocalConvertorTime(utcDateStr);
  } else if (diffDays === 1) {
    formattedDate = `Yesterday`;
  } else if (diffDays <= 7) {
    formattedDate = `${date.toLocaleDateString()}`;
  } else {
    formattedDate = date.toLocaleDateString();
  }
  return formattedDate;
}
export function formatDateBasedOnRecency2(utcDateStr) {
  if (utcDateStr == null || utcDateStr == undefined) {
    return '';
  }
  const localDate = new Date(Date.parse(utcDateStr + ' UTC')).toLocaleString();
  const date = new Date(utcDateStr);
  const now = new Date();
  const oneDay = 24 * 60 * 60 * 1000;

  const diffDays = Math.floor((now - date) / oneDay);


  let formattedDate;
  if (diffDays == 0) {
    formattedDate = `Today`;
  } else if (diffDays == 1) {
    formattedDate = `Yesterday`;
  } else if (diffDays <= 7) {
    formattedDate = `${date.toLocaleDateString()}`;
  }
  return formattedDate;
}

export function formatTime(date) {
  const dateObj = new Date(date);
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const period = hours >= 12 ? 'PM' : 'AM';
  const adjustedHours = hours % 12 || 12;
  const formattedHours = String(adjustedHours);
  const formattedMinutes = String(minutes).padStart(2, '0');
  return `${formattedHours}:${formattedMinutes} ${period}`;
}

export const formatTime1 = (seconds) => {
  if (isNaN(seconds) || seconds == null || seconds < 0) {
    return '0:00';
  }
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};

const emojiLib = require('emoji_encoding_decoding');
export function decodeUnicodeEscapes(text) {
  const unicodeEscapeRegex = /\\u[\dA-F]{4}/gi;
  return text.replace(unicodeEscapeRegex, (match) => {
    return String.fromCharCode(parseInt(match.replace('\\u', ''), 16));
  });
}

export function processString(inputString) {
  const decodedString = decodeUnicodeEscapes(inputString);
  const contentType = determineContentType(decodedString);

  switch (contentType) {
    case 'emoji':
      return emojiLib.EmojiDecode(decodedString);
    case 'text':
    default:
      return decodedString;
  }
}

export function determineContentType(text) {
  const emojiRegex = /[\p{Emoji}]/u;
  const decodedText = decodeUnicodeEscapes(text);
  if (emojiRegex.test(decodedText)) {
    return 'emoji';
  }
  return 'text';
}