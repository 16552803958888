import React, { useContext } from 'react'
import Chatlist from '../../components/Chat/Chatlist'
import SocketContext, { SocketProvider } from '../../context/SocketContext';

function Chat() {

  const socket = useContext(SocketContext);

  return (
    <section id="main-box">
      <div className="container-box">
        <div className="right-sidebar">
          <div className="main-contant">
            <div className="container-fluid">
              <section className="contant-box" id="chatSection">
                <div className="row">
                  <SocketProvider>
                    <Chatlist />
                  </SocketProvider>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Chat