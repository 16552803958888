import { useSelector } from 'react-redux';
import api from '../../services/Api';

export const SET_CONV_LIST = 'SET_CONV_LIST';
export const SET_NEWGROUP_LIST = 'SET_NEWGROUP_LIST';
export const SET_CURR_CHAT = 'SET_CURR_CHAT';
export const SET_CONTACT_LIST = 'SET_CONTACT_LIST';
export const SET_CURRCHAT_MEMBERS = 'SET_CURRCHAT_MEMBERS';

export const setCurrChat = (currChat) => ({
    type: SET_CURR_CHAT,
    payload: currChat
});
export const setGroupMembers = (currChatMembers) => ({
    type: SET_CURRCHAT_MEMBERS,
    payload: currChatMembers
});
export const setContactList = (currList) => ({
    type: SET_CONTACT_LIST,
    payload: currList
});
export const setConvList = (currList) => ({
    type: SET_CONV_LIST,
    payload: currList
});
export const setNewGroupList = (currList) => ({
    type: SET_NEWGROUP_LIST,
    payload: currList
});
export const getUserList = () => async (dispatch, navigate) => {
    const userToken = localStorage.getItem('token')
    try {
        const response = await api.get("/web_conversation_list?size=5000", userToken);
        if (response.code === 200) {
            setConversationMessage(response.result, dispatch, navigate);
        }else{
            return 0
        }

    } catch (error) {
        console.error(error);
        navigate("/");
        localStorage.removeItem('userData');
        localStorage.removeItem('token');
    } finally {
    }
}
export const setConversationMessage = async (conversationList, dispatch, navigate) => {
    try {
        // const { conversationList } = useSelector(state => state.conversation);
        const userToken = localStorage.getItem('token');
        const updatedConversationList = await Promise.all(conversationList.map(async (ele) => {
            try {
                const response = await api.get(`/web_old_messages_list?conv_id=${ele.conv_id}&message_id=0&size=100`, userToken);
                if (response && response.code === 200) {
                    return {
                        ...ele,
                        messageList: response.result || []
                    };
                } else {
                    return {
                        ...ele,
                        messageList: [] // Default to an empty array if no messages
                    };
                }
            } catch (error) {
                console.error(`Error fetching messages for conversation ${ele.conv_id}:`, error);
                navigate("/");
                localStorage.removeItem('userData');
                localStorage.removeItem('token');
                return {
                    ...ele,
                    messageList: [] // Default to an empty array in case of error
                };
            }
        }));

        dispatch(setConvList(updatedConversationList));
    } catch (error) {
        console.error("Error fetching messages:", error);
    }
};

export const getMembersList = (convId) => async (dispatch, navigate) => {
    const userToken = localStorage.getItem('token')
    try {
        const response = await api.get(`/web_group_member_list?conv_id=${convId}&size=1000`, userToken);
        if (response.code === 200) {
            dispatch(setGroupMembers(response.result));
        }
    } catch (error) {
        console.error(error);
        navigate("/");
        localStorage.removeItem('userData');
        localStorage.removeItem('token');
    } finally {
    }
}

export const getContactList = (conversationList) => async (dispatch, navigate) => {
    const userToken = localStorage.getItem('token')
    try {
        const response = await api.get("/web_user_contact_list", userToken);
        if (response.code === 200) {
            if (conversationList && conversationList.length > 0) {
                const existingConversationList = conversationList;
                const newList = [...existingConversationList, ...response.result];
                // const existingConvIds = new Set(existingConversationList.map(contact => contact.user_id));
                // const filteredNewList = newList.filter(contact => !existingConvIds.has(contact.user_id));
                dispatch(setContactList(newList));
            } else {
                dispatch(setContactList(response.result));
            }
        }
    } catch (error) {
        console.error(error);
        navigate("/");
        localStorage.removeItem('userData');
        localStorage.removeItem('token');
    } finally {
    }
}
