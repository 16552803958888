
import React, { forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { formatTime1 } from '../../../utils/helpers/CommFun';

const AudioMessage = forwardRef(({ message, isPlaying, progress, currentTime, onPlayPause, onSeek, onTimeUpdate }, ref) => {
    const audioRef = useRef(null);
    const progressBarRef = useRef(null);

    useImperativeHandle(ref, () => ({
        play: () => audioRef.current.play(),
        pause: () => audioRef.current.pause(),
        getAudio: () => audioRef.current,
    }));

    useEffect(() => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.play();
            } else {
                audioRef.current.pause();
            }
        }
    }, [isPlaying]);

    useEffect(() => {
        const handleAudioEnded = () => {
            onPlayPause(null);
            if (progressBarRef.current) {
                progressBarRef.current.style.transition = 'none'; 
                progressBarRef.current.style.width = '0%'; 
            }
            onSeek(message.message_id, 0);
        };

        if (audioRef.current) {
            audioRef.current.addEventListener('ended', handleAudioEnded);
        }
        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener('ended', handleAudioEnded);
            }
        };
    }, [onPlayPause, message.message_id, onSeek]);

    useEffect(() => {
        if (audioRef.current) {
            const { currentTime, duration } = audioRef.current;
            if (currentTime === duration) {
            }
        }
    }, [currentTime, isPlaying]);

    useEffect(() => {
        if (progressBarRef.current) {
            progressBarRef.current.style.width = `${progress}%`;
        }
    }, [progress]);

    const handleTimeUpdate = () => {
        if (audioRef.current) {
            const { currentTime, duration } = audioRef.current;
            onTimeUpdate(message.message_id, currentTime, duration);
        }
    };

    const handleProgressClick = (event) => {
        const progressBar = event.currentTarget;
        const rect = progressBar.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const width = rect.width;
        const newTime = (x / width) * audioRef.current.duration;
        if (audioRef.current) {
            audioRef.current.currentTime = newTime;
            if (!isPlaying) {
                audioRef.current.play();
                onPlayPause(message.message_id);
            }
            onSeek(message.message_id, newTime);
        }
    };

    const duration = audioRef.current ? audioRef.current.duration : 0;

    return (
        <>
            <div className="play-pause-btn">
                {isPlaying ? (
                    <button className="pause-btn" onClick={() => onPlayPause(null)}>
                      <span>❚❚ </span>
                    </button>
                ) : (
                    <button className="play-btn" onClick={() => onPlayPause(message.message_id)}>
                          <span>▶</span>
                    </button>
                )}
            </div>
            <div className="progress" onClick={handleProgressClick}>
                <div
                    className="progress-bar"
                    role="progressbar"
                    ref={progressBarRef}
                    style={{ width: `${progress}%`, transition: 'width 0.5s ease' }}
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
            </div>
            <div className="timer-box">
                <span>{formatTime1(duration - currentTime)}</span>
            </div>
            <audio
                ref={audioRef}
                src={message.content}
                onTimeUpdate={handleTimeUpdate}
                preload="auto"
            />
        </>
    );
});

export default AudioMessage;
